import getDate from "App/helpers/date";
import getDateTime from "App/helpers/dateTime";
import { convertPriceToAda, getHexToStr } from "App/helpers/utilities";
import "./FormatField.scss"
import getDayMonthYearDate from "App/helpers/dayMonthYearDate";

const FormatField = ({ type, value, maxWidth = '' }) => {
    switch (type) {
        case 'date':
            return getDate(value)
        case 'dateTime':
            return getDateTime(value)
        case 'dayMonthYearDate':
            return getDayMonthYearDate(value)
        case 'hexToString':
            return getHexToStr(value)
        case 'adaConvert':
            return convertPriceToAda(value)
        case 'address':
            return <div className="address" style={{ maxWidth: maxWidth }}>{value}</div>
        default:
            break;
    }

}

export default FormatField