import "./FrameUpload.scss";
import { Rnd } from "react-rnd";
import { Fragment, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { aspectRatio } from "App/helpers/utilities";
import Image from "../Image";
import FramePreview from "../FramePreview";

const FrameUpload = ({ src,
    className,
    setToggleChangeRotate,
    toggleChangeRotate = false,
    setToggleChange,
    row = {},
    toggleChange = false,
    upload,
    onChange,
    onFramePosUpdate,
    metaInfo,
    dummyImage,
    disableDragging = false,
    isEdit = false,
    formValues, metaInfoNew, tabIndex = 0
}) => {
    const defaultConfig = {
        x: 10,
        y: 10,
        width: 150,
        height: 150,
    }
    const [mprConfig, setMprConfig] = useState(defaultConfig)
    const [asRat, setAsRat] = useState('1:1');
    // const [imgSrc, setImgSrc] = useState('1:1');
    const mapOuter = useRef()
    const imageUpload = (e) => {
        onChange(e.target.files[0])
    }
    const imgSrc  = src ?? '';
    const onDragStopHandler = (e, pos) => {
        const { x, y } = pos;
        setMprConfig((prev) => {
            return {
                ...prev, x, y
            }
        })
    }
    const onResizeHandler = (e, direction, ref, delta, pos) => {
        const { x, y } = pos;
        const { offsetWidth: width, offsetHeight: height } = ref;
        setMprConfig((prev) => {
            return {
                ...prev,
                width, height, x, y
            }
        })
    }
    const emitFrameDim = useCallback(() => {
        const { offsetWidth: width, offsetHeight: height } = mapOuter.current;
        const xPercent = mprConfig.x / width * 100;
        const yPercent = mprConfig.y / height * 100;
        const widthPercent = mprConfig.width / width * 100;
        const heightPercent = mprConfig.height / height * 100;
        const imgAspct = aspectRatio(mprConfig.width, mprConfig.height);
        setAsRat(imgAspct);
        const obj = {
            frame: {
                width,
                height,
                aspectRatio: aspectRatio(width, height)
            },
            image: {
                ...mprConfig,
                xPercent,
                yPercent,
                widthPercent,
                heightPercent,
                aspectRatio: imgAspct
            }
        }
        if (onFramePosUpdate) {
            onFramePosUpdate(obj)
        }
    }, [mprConfig, onFramePosUpdate])
    const buildFrame = (frameMeta, outerMeta) => {
        const { xPercent, yPercent, widthPercent = 0, heightPercent = 0 } = frameMeta;
        const { offsetWidth, offsetHeight } = outerMeta;
        const calcWidth = Math.round((offsetWidth / 100) * widthPercent);
        const calcHeight = Math.round((offsetHeight / 100) * heightPercent);
        const calcX = Math.round((offsetWidth / 100) * xPercent);
        const calcY = Math.round((offsetHeight / 100) * yPercent);
        const obj = { x: calcX, y: calcY, width: calcWidth, height: calcHeight };
        return obj
    }
    const imageLoaded = (e) => {
        if (metaInfo) {
            const obj = buildFrame(metaInfo.image, mapOuter.current);
            setMprConfig(obj)
        }
        emitFrameDim();
    }
    useEffect(() => {
        emitFrameDim();
    }, [emitFrameDim, imgSrc]);
    useLayoutEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            const obj = buildFrame(metaInfo.image, mapOuter.current);
            setMprConfig(obj)
        })
        if (metaInfo) {
            resizeObserver.observe(mapOuter.current)
        }
        return () => {
            resizeObserver.disconnect();
        }
    }, [metaInfo])

    const onChangeHandler = (e) => {
        const label = e?.currentTarget?.dataset?.label
        const value = parseInt(e?.target?.value)
        const obj = {
            [label]: value
        }
        setMprConfig((prev) => {
            return {
                ...prev,
                ...obj
            }
        })

    }

    const resetDetails = (e) => {
        let oldMetaInfo = (formValues?.metaInfo && tabIndex === 0) ? formValues?.metaInfo?.portrait : (formValues?.metaInfo && tabIndex === 1) ? formValues?.metaInfo?.landscape : mprConfig;
        const resizeObserver = new ResizeObserver((entries) => {
            const obj = buildFrame(oldMetaInfo?.image, mapOuter.current);
            setMprConfig(obj)
        })
        if (metaInfo) {
            resizeObserver.observe(mapOuter.current)
        } else {             
            setMprConfig(defaultConfig)
        }
        return () => {
            resizeObserver.disconnect();
        }
    }
    return (
        <div className={`${className}`}>

            <div className="flex justify-center">
                <div className="flex flex-col">
                    

                    <div className="flex frames-upload flex-row gap-8  mb-4">
                        <div className="flex flex-col">
                            <div className="preview-head">Position</div>
                            <div className="flex gap-2 pt-1">
                                <div className="gap-2 flex input-full">
                                    <span className="w-6">X :</span>
                                    <input type="number" data-label="x" step={`1`} onChange={onChangeHandler} value={mprConfig?.x} /></div>
                                <div className="gap-2 flex input-full">
                                    <span >Y :</span><input data-label="y" step={`1`} onChange={onChangeHandler} type="number" value={mprConfig?.y} /></div>
                            </div>
                        </div>
                        
                        <div className="flex flex-col">
                            <div className="preview-head">Size</div>

                            <div className="flex gap-2 pt-1">
                                <div className="gap-2 flex input-full"><span className="w-6">W :</span>
                                    <input onChange={onChangeHandler} data-label="width" min="0" step={`1`} type="number" value={mprConfig?.width} /></div>
                                <div className="gap-2 flex input-full"> <span >H :</span>
                                    <input type="number" onChange={onChangeHandler} min="0" step={`1`} data-label="height" value={mprConfig?.height} /></div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-[6px]">
                        <div className="preview-head">Preview</div>
                        <div>                            
                            <div className="upload-image">
                                <div className="image-wrapper" ref={mapOuter}>
                                    {imgSrc && (<Rnd
                                        className={`image-mapper ${toggleChange ? 'on-top' : ''}`}
                                        default={defaultConfig}
                                        onDragStop={onDragStopHandler}
                                        onResize={onResizeHandler}
                                        disableDragging={!!disableDragging}
                                        size={{ width: mprConfig.width, height: mprConfig.height }}
                                        position={{ x: mprConfig.x, y: mprConfig.y }}
                                    >
                                        <h4>{mprConfig.width} X {mprConfig.height}</h4>
                                        <p>Move or Resize</p>
                                        <h4>{asRat}</h4>
                                    </Rnd>)}
                                    <FramePreview classes="upload-image" className="image-container" noStyle={true} metaInfo={metaInfoNew} src={imgSrc ?? dummyImage} toggleChange={toggleChange} mprConfig={mprConfig} />
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <button className={`w-full flex pt-4 justify-start ${imgSrc ? 'reset-frame' : 'no-reset'}`} onClick={resetDetails}>Reset</button>
                </div>
                {(upload && !isEdit) && <div className="flex items-center"> <div className="img-wid">
                        <Fragment>
                            <div className="frame-upload-text flex flex-col mt-5">
                                <div className="image-label">
                                    <input type="file" className="cursor-pointer" onChange={imageUpload} />
                                </div>

                                <div className="flex justify-between w-full">
                                    <div className="flex flex-col cursor-pointer">
                                        <h4>Upload Frame</h4>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                </div></div>}
            </div>
        </div>


    )
}

export default FrameUpload