import Button from "App/Components/UI/Button";
import SearchFilter from "App/Components/UI/SearchFilter";
import Table from "App/Components/UI/Table";
import TableHeader from "App/Components/UI/Table/Components/TableHeader";
import User from "App/Components/UI/User";
import tableSort from "App/helpers/tableSort";
import React, { useCallback, useEffect, useState } from "react";
import ButtonLink from "App/Components/UI/ButtonLink";
import Status from "App/Components/UI/Status";
import ButtonAction from "App/Components/UI/ButtonAction";
import ActionButton from "Assets/Images/ActionButton.svg";
import SidePanel from "App/Components/UI/SidePanel";
import WalletAddressDetails from "./Components/WalletAddressDetails";
import Popup from "App/Components/UI/Popup";
import CreateUser from "./Components/CreateUser";
import MenuDel from "Assets/Images/menu-del.svg";
import MenuPen from "Assets/Images/menu-pen.svg";
import MenuLink from "Assets/Images/menu-link.svg";
import MenuGallery from "Assets/Images/menu-gallery.svg";
import MenuDetails from "Assets/Images/menu-details.svg";
import useHttp from "App/hooks/use-http";
import { useStore } from "App/hooks-store/store";
import Pagination from "App/Components/UI/Pagination";
import { useNavigate } from "react-router-dom";
import "./Components/TransactionDetails/TransactionDetails.scss";
import ConfirmPopup from "App/Components/UI/ConfirmPopup";
import ViewDetails from "./Components/ViewDetails";
import { LIST_LIMIT } from "App/constants";

const actionMenu = [{
    label: 'View Gallery',
    action: 'viewGallery',
    src: MenuGallery
}, {
    label: 'View Details',
    action: 'viewDetails',
    src: MenuDetails
}, {
    label: 'Edit',
    action: 'edit',
    src: MenuPen
}, {
    label: 'Block',
    action: 'block',
    src: MenuLink
},
{
    label: 'Delete',
    action: 'delete',
    src: MenuDel
}]
const menuAction = [{
    label: 'View Gallery',
    action: 'viewGallery',
    src: MenuGallery
}, {
    label: 'View Details',
    action: 'viewDetails',
    src: MenuDetails
}, {
    label: 'Edit',
    action: 'edit',
    src: MenuPen
},
{
    label: 'Unblock',
    action: 'Unblock',
    src: MenuLink
},
{
    label: 'Delete',
    action: 'delete',
    src: MenuDel
}]

const paginationConfig = {
    perPage: LIST_LIMIT
}
const UserList = React.memo(() => {
    const [dateMenu, setDateMenu] = useState([{
        label: 'Date Range',
        action: 'dateRange',
        selected: false,
        popup: true
    }
    ])
    const [initial, setInitial] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const { triggerAPI } = useHttp();
    const dispatch = useStore(false)[1];
    const [list, setList] = useState([]);

    const [selectedData, setSelectedData] = useState({})
    const [showWalletAddress, setShowWalletAddress] = useState({ mode: false });
    const [showBlockPopup, setShowBlockPopup] = useState({ mode: false });
    const [showCreateUserPopup, setShowCreateUserPopup] = useState({ mode: false });
    const [showDeletePopup, setShowDeletePopup] = useState({ mode: false });
    const [showViewDetailsPopup, setShowViewDetailsPopup] = useState({ mode: false });
    const [showMenuAction, setShowMenuAction] = useState();
    const [search, setSearch] = useState('')
    const [sort, setSort] = useState({ direction: '', sortKey: 'name' });
    const [selectedDate, setSelectedDate] = useState('')
    const onSortHandler = useCallback((sortKey) => {
        const dir = sort.sortKey === sortKey ? sort.direction : '';
        const direction = tableSort(dir)
        setSort({ direction, sortKey })
    }, [sort]);
    const navigate = useNavigate()
    const userCreationHandler = () => {
        setSelectedData({})

        setShowCreateUserPopup({ mode: true })
    }
    const viewWalletAddressHandler = (e) => {
        setSelectedData(e.row)
        setShowWalletAddress({ mode: true })
    }
    const viewWalletTransactionHandler = (e) => {
        const { id = '' } = e.row;
        navigate(`/dashboard/user-transactions/${id}`, { state: { from: 'user' } })
    }
    const onCloseCreateUser = (data) => {
        setShowCreateUserPopup({ mode: false })
        if (data) {
            getUserList()
        }
    }

    const closeWalletPanel = () => {
        setShowWalletAddress({ mode: false })
    }
    const actionHandler = (e) => {
        setSelectedData(e.row)
        setShowMenuAction(e.action)
        if (e.action === "block" || e.action === "Unblock") {
            setShowBlockPopup({ mode: true })
        }
        else if (e.action === "delete") {
            setShowDeletePopup({ mode: true })
        }
        else if (e.action === "viewDetails") {
            setShowViewDetailsPopup({ mode: true })
        }

        else if (e.action === "viewGallery") {
            navigate(`/dashboard/gallery-management/gallery/${e.row.id}`)
        }
        else if (e.action === "edit") {
            setShowCreateUserPopup({ mode: true })
        }
    }

    const apiResult = (res) => {
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
        setShowBlockPopup({ mode: false })
        getUserList()
    }

    const apiDeleteResult = (res) => {
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
        setShowDeletePopup({ mode: false })
        getUserList()
    }


    const onCloseBlockUser = (data) => {
        if (data) {
            blockUserHandler(data)
        }
        else {
            setShowBlockPopup({ mode: false })

        }
    }

    const onCloseDeleteUser = (data) => {
        if (data) {
            deleteUserHandler()
        }
        else {
            setShowDeletePopup({ mode: false })

        }
    }

    const blockUserHandler = (e) => {
        const data = {
            id: selectedData?.id,
        }
        dispatch('showSpinner');
        if (showMenuAction === 'block') {
            triggerAPI({
                url: 'admin/user/block', data, method: 'patch'
            }, apiResult);
        }
        else {
            triggerAPI({
                url: 'admin/user/unBlock', data, method: 'patch'
            }, apiResult);
        }

    }
    const onFilterData = (e) => {
        setSelectedDate(e)
    }
    const searchHandler = (e) => {
        setSearch(e)
    }

    const deleteUserHandler = () => {
        const data = {
            id: selectedData.id,
        }
        dispatch('showSpinner');
        triggerAPI({
            url: 'admin/user/delete', data, method: 'delete'
        }, apiDeleteResult);
    }

    const onPageChangeHandler = (page) => {
        setPage(page)
        const root = document.getElementById('content');
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
    }
    const getUserlistResult = useCallback((res) => {
        dispatch('hideSpinner');
        const { list, pageMeta } = res.data
        setList(list)
        setTotalPage(pageMeta.totalItems)
    }, [dispatch])

    const getUserList = useCallback(() => {
        dispatch('showSpinner');
        let filter;
        if (sort.direction || selectedDate.from) {
            filter = {
                sortBy: sort.sortKey,
                orderBy: sort.direction,
                from: selectedDate.from,
                to: selectedDate.to
            }
        }
        else {
            filter = ''
        }

        triggerAPI({
            url: `admin/user/list`, data: {
                page: page,
                items: paginationConfig.perPage,
                search: `${search}`, filter
            }, method: 'post'
        }, getUserlistResult);
    }, [dispatch, getUserlistResult, page, search, selectedDate, sort, triggerAPI])

    useEffect(() => {
        if (page !== 1) {
            setPage(1)
        }
        else {
            getUserList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort, search, selectedDate])

    useEffect(() => {
        if (!initial) {
            getUserList();
        }
        setInitial(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <div className='search-filter'>
                    <SearchFilter position={{ right: 80, top: 10 }} onSearch={searchHandler} setActionMenu={setDateMenu} actionMenu={dateMenu} onFilterData={onFilterData} />
                </div>
                <div>
                    <Button label="Add User" onClick={userCreationHandler} />
                </div>
            </div>
            <div className="mb-40">
                <Table>
                    <thead className="text-left">
                        <tr>
                            <th>
                                <TableHeader label="User" sort={sort} sortKey="name" onSort={onSortHandler} />
                            </th>
                            <th>
                                <TableHeader label="Email" />
                            </th>
                            <th>
                                <TableHeader label="Wallet Address" />
                            </th>
                            <th>
                                <TableHeader label="Transactions" />
                            </th>
                            <th>
                                <TableHeader label="Status" />
                            </th>
                            <th>
                                <TableHeader label="Actions" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {list?.map((usr) => <tr key={usr.id}>
                            <td>
                                <User label={usr?.username} src={usr.profilePicUrl} className="user-table" />
                            </td>
                            <td>
                                {usr.email}
                            </td>
                            <td>
                                <ButtonLink row={usr} label="View" onClick={viewWalletAddressHandler} />
                            </td>
                            <td>
                                <ButtonLink label="View" row={usr} onClick={viewWalletTransactionHandler} />
                            </td>
                            <td>
                                <Status label={usr.isBlocked ? 'Blocked' : 'Active'} />
                            </td>
                            <td>
                                <div className="flex justify-end items-center">
                                    <ButtonAction src={ActionButton} menu={usr.isBlocked ? menuAction : actionMenu} row={usr} onAction={actionHandler} />
                                </div>

                            </td>
                        </tr>)}
                    </tbody>
                </Table>
                {!list?.length &&
                    <div className="text-center no-rows pt-4">
                        <p>No data</p>
                    </div>}
                <div className="mt-6">
                    <Pagination itemsPerPage={paginationConfig.perPage} total={totalPage} rows={list.length} onPageChange={onPageChangeHandler} selected={page} />
                </div>
                <SidePanel show={showWalletAddress} title="Wallet Address">
                    <WalletAddressDetails row={selectedData} triggerClose={closeWalletPanel} />
                </SidePanel>
                <Popup show={showCreateUserPopup}
                    style={{ 'maxWidth': '51.42rem' }}
                    title={`${selectedData.id ? 'Edit User' : 'Add User'}`} cssClass="user-create-pop" onClose={onCloseCreateUser}>
                    <CreateUser triggerClose={onCloseCreateUser} row={selectedData} />
                </Popup>



                <ConfirmPopup row={selectedData} show={showBlockPopup} content={selectedData.isBlocked ? 'Are you sure want to unblock this user?' : 'Are you sure want to block this user?'} buttonYes={selectedData.isBlocked ? 'Unblock' : 'Block'} buttonCancel="Cancel"
                    style={{ 'maxWidth': '39rem' }}
                    title={selectedData.isBlocked ? 'Unblock User' : 'Block User'} onClose={onCloseBlockUser}>
                    <div className="flex block-user-card">
                        <User src={selectedData.profilePicUrl} label={selectedData.name} email={selectedData.email} className="wallet-profile" />
                    </div>
                </ConfirmPopup>

                <ConfirmPopup row={selectedData} show={showDeletePopup} content='Are you sure want to delete this user?' buttonYes="Delete" buttonCancel="Cancel"
                    style={{ 'maxWidth': '39rem' }}
                    title="Delete User" cssClass="delete-user" onClose={onCloseDeleteUser}>
                    <div className="flex block-user-card">
                        <User src={selectedData.profilePicUrl} label={selectedData.name} email={selectedData.email} className="wallet-profile" />
                    </div>
                </ConfirmPopup>
                <ViewDetails show={showViewDetailsPopup} row={selectedData} />
            </div>
        </div>
    )
})
export default UserList;