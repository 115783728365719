/* eslint-disable react-hooks/exhaustive-deps */
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useCallback, useState, useEffect } from "react";
import Toggle from "./Components/Toggle";

const RegistrationManagement = () => {
  const dispatch = useStore(false)[1];
  const [registration, setRegistration] = useState(false);
  const { triggerAPI } = useHttp();
  useEffect(() => {
    dispatch("setPageTitle", "Registration Management");
  }, [dispatch]);
  const getRegistrationStatusResponse = useCallback(
    (res) => {
      dispatch("hideSpinner");
      setRegistration(res?.data);
    },
    [dispatch]
  );

  const getRegistrationStatus = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `app-preference/get`,
        method: "get",
      },
      getRegistrationStatusResponse
    );
  }, [dispatch, getRegistrationStatusResponse, triggerAPI]);
  useEffect(() => {
    getRegistrationStatus();
  }, []);

  const updateToggleResponse = useCallback(
    (res) => {
      const { message } = res;
      dispatch("hideSpinner");
      dispatch("showToast", { toast: { toastMode: "success", message: message || "Registration condition updated"  } });
      getRegistrationStatus();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const updateToggle = useCallback(
    (id, checked) => {
      dispatch("showSpinner");
      triggerAPI(
        {
          url: `app-preference/update`,
          data: {id:1, allowNewRegistration: checked },
          method: "patch",
        },
        updateToggleResponse,
        (err) => {
          dispatch("hideSpinner");
          dispatch("showToast", {
            toast: {
              toastMode: "error",
              message:
                err?.response?.data?.error?.message ||
                "Registration updates failed",
            },
          });
        }
      );
    },
    [dispatch, updateToggleResponse, triggerAPI]
  );
  const onToggleChange = (id, checked) => {
    updateToggle(id, checked);
  };

  return (
    <div className="py-4">
      <Toggle onToggle={onToggleChange} status={registration?.allowNewRegistration} />
    </div>
  );
};
export default RegistrationManagement;
