import Button from "App/Components/UI/Button";
import User from "App/Components/UI/User";
import { getTwoDigitCount } from "App/helpers/utilities";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useCallback, useEffect, useState } from "react";
import "./WalletAddressDetails.scss";

const WalletAddressDetails = (props) => {
    const [list, setList] = useState([]);
    const { triggerAPI } = useHttp();
    const dispatch = useStore(false)[1];
    const walletCount = getTwoDigitCount(list.length);
    const { row } = props;

    const getWalletResult = useCallback((res) => {
        dispatch('hideSpinner');
        const list = res.data
        setList(list)
    }, [dispatch])

    const getWalletDetails = useCallback(() => {
        dispatch('showSpinner');
        triggerAPI({
            url: `admin/connected-wallet/user/${row.id}`, method: 'get'
        }, getWalletResult);
    }, [dispatch, getWalletResult, row.id, triggerAPI])

    useEffect(() => {
        getWalletDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <div className="transaction transaction-header">
                <User label={row.name} email={row.email} src={row.profilePicUrl} className="wallet-profile" />
            </div>
            <div className="transaction-address pt-5">Total Wallet addresses: {walletCount}</div>
            <div className="pt-4">
                {list?.length ? list.map((wallet, i) =>
                    <div className="transaction pt-4 mt-5" key={i}>
                        <div className="wallet-label">Wallet Address {i + 1}</div>

                        <span className='transaction-input'>{wallet.walletAddress}</span>
                    </div>

                ) :
                    <div className="flex justify-center p-20 nodata">
                        <p>No Data Found</p>
                    </div>
                }

                <div className="flex justify-center pt-7">
                    <Button className="wallet-button" onClick={props.triggerClose} label='Ok'></Button>
                </div>
            </div>

        </div>

    )
}
export default WalletAddressDetails;