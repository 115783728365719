/* eslint-disable react-hooks/exhaustive-deps */
import { NavLink } from "react-router-dom";
import "./Sidebar.scss";
import AnalyticsDashboardIcon from "Assets/Images/AnalyticsDashboardIcon.svg";
import Users from "Assets/Images/Users.svg";
import FrameManagement from "Assets/Images/FrameManagement.svg";
import ArtBank from "Assets/Images/ArtBank.svg";
import NFT from "Assets/Images/NFT.svg";
import BackgroundWall from "Assets/Images/BackgroundWall.svg";
import Gallery from "Assets/Images/Gallery.svg";
import AccessPlan from "Assets/Images/AccessPlan.svg";
import InformationDesk from "Assets/Images/InformationDesk.svg";
import TransactionHistory from "Assets/Images/TransactionHistory.svg";
import SubscriptionPlans from "Assets/Images/SubscriptionPlans.svg";
import Project from "Assets/Images/Project.png";
import MenuItem from "./Components/MenuItem";
import { useMemo } from "react";
import storage from "App/helpers/storage";

const Sidebar = () => {
  const menu = [
    {
      title: "Analytics Dashboard",
      element: (
        <li key={"menu-1"}>
          <NavLink
            className={({ isActive }) => (isActive ? "linkActive" : "")}
            to="analytics"
          >
            <MenuItem
              src={AnalyticsDashboardIcon}
              label="Analytics Dashboard"
            />
          </NavLink>
        </li>
      ),
    },
    {
      title: "User Management",
      element: (
        <li key={"menu-2"}>
          <NavLink
            className={({ isActive }) => (isActive ? "linkActive" : "")}
            to="users"
          >
            <MenuItem src={Users} label="Users" />
          </NavLink>
        </li>
      ),
    },
    {
      title: "Registration Management",
      element: (
        <li key={"menu-3"}>
          <NavLink
            className={({ isActive }) => (isActive ? "linkActive" : "")}
            to="registration-management"
          >
            <MenuItem src={Project} label="Registration" />
          </NavLink>
        </li>
      ),
    },
    {
      title: "Admin Management",
      element: (
        <li key={"menu-4"}>
          <NavLink
            className={({ isActive }) => (isActive ? "linkActive" : "")}
            to="sub-admin"
          >
            <MenuItem src={Users} label="Admin" />
          </NavLink>
        </li>
      ),
    },
    {
      title: "Roles And Permissions",
      element: (
        <li key={"menu-5"}>
          <NavLink
            className={({ isActive }) => (isActive ? "linkActive" : "")}
            to="role-and-permissions"
          >
            <MenuItem src={Project} label="Role & Permissions" />
          </NavLink>
        </li>
      ),
    },
    {
      title: "Community Management",
      element: (
        <li key={"menu-6"}>
          <NavLink
            className={({ isActive }) => (isActive ? "linkActive" : "")}
            to="community-management"
          >
            <MenuItem src={Users} label="Community" />
          </NavLink>
        </li>
      ),
    },
    {
      title: "Collection Management",
      element: (
        <li key={"menu-7"}>
          <NavLink
            className={({ isActive }) => (isActive ? "linkActive" : "")}
            to="collection-management"
          >
            <MenuItem src={Users} label="Collection" />
          </NavLink>
        </li>
      ),
    },
    {
      title: "Nft Frame Management",
      element: (
        <li key={"menu-8"}>
          <NavLink
            className={({ isActive }) => (isActive ? "linkActive" : "")}
            to="frames"
          >
            <MenuItem src={FrameManagement} label="Frame" />
          </NavLink>
        </li>
      ),
    },
    {
      title: "Projects",
      element: (
        <li key={"menu-9"}>
          <NavLink
            className={({ isActive }) => (isActive ? "linkActive" : "")}
            to="project-management"
          >
            <MenuItem src={Project} label="Projects" />
          </NavLink>
        </li>
      ),
    },
    {
      title: "Art Bank Management",
      element: (
        <li key={"menu-10"}>
          <NavLink
            className={({ isActive }) => (isActive ? "linkActive" : "")}
            to="art-bank-management"
          >
            <MenuItem src={ArtBank} label="The Art Bank" />
          </NavLink>
        </li>
      ),
    },
    {
      title: "NFT",
      element: (
        <li key={"menu-11"}>
          <NavLink to="">
            <MenuItem src={NFT} label="NFT" />
          </NavLink>
        </li>
      ),
    },
    {
      title: "Background Wall Management",
      element: (
        <li key={"menu-12"}>
          <NavLink
            className={({ isActive }) => (isActive ? "linkActive" : "")}
            to="background-wall"
          >
            <MenuItem src={BackgroundWall} label="Background Wall" />
          </NavLink>
        </li>
      ),
    },
    {
      title: "Gallery Management",
      element: (
        <li key={"menu-13"}>
          <NavLink
            className={({ isActive }) => (isActive ? "linkActive" : "")}
            to="gallery-management"
          >
            <MenuItem src={Gallery} label="Gallery" />
          </NavLink>
        </li>
      ),
    },
    {
      title: "Monet Reward",
      element: (
        <li key={"menu-19"}>
          <NavLink
            className={({ isActive }) => (isActive ? "linkActive" : "")}
            to="monet-reward"
          >
            <MenuItem src={TransactionHistory} label="Monet Reward" />
          </NavLink>
        </li>
      ),
    },
    {
      title: "Subscription Plans",
      element: (
        <li key={"menu-14"}>
          <NavLink
            className={({ isActive }) => (isActive ? "linkActive" : "")}
            to={"subscription"}
          >
            <MenuItem src={SubscriptionPlans} label={"Subscription"} />
          </NavLink>
        </li>
      ),
    },
    {
      title: "Access Plan Management",
      element: (
        <li key={"menu-15"}>
          <NavLink
            className={({ isActive }) => (isActive ? "linkActive" : "")}
            to="access-plan"
          >
            <MenuItem src={AccessPlan} label="Access Plan" />
          </NavLink>
        </li>
      ),
    },
    {
      title: "Information Management",
      element: (
        <li key={"menu-16"}>
          <NavLink
            className={({ isActive }) => (isActive ? "linkActive" : "")}
            to="information-desk"
          >
            <MenuItem src={InformationDesk} label="Information Desk " />
          </NavLink>
        </li>
      ),
    },
    {
      title: "Transaction History",
      element: (
        <li key={"menu-17"}>
          <NavLink
            className={({ isActive }) => (isActive ? "linkActive" : "")}
            to="transaction-history"
          >
            <MenuItem src={TransactionHistory} label="Transaction History " />
          </NavLink>
        </li>
      ),
    },
    {
      title: "Security",
      element: (
        <li key={"menu-18"}>
          <NavLink
            className={({ isActive }) => (isActive ? "linkActive" : "")}
            to="security"
          >
            <MenuItem src={TransactionHistory} label="Security" />
          </NavLink>
        </li>
      ),
    },
  ];
  const tokenStore = storage();

  const permission = useMemo(() => {
    let menuData = tokenStore.get("menu");
    if (menuData !== undefined) {
      return JSON.parse(menuData);
    }
    return [];
  }, [tokenStore.get("menu")]);

  const sideMenu = useMemo(() => {
    return menu.filter((val) =>
      permission?.find(
        (el) =>
          el?.isEnabled &&
          el.permissions.permission.toLowerCase() === val.title.toLowerCase()
      )
    );
  }, [permission]);

  return <ul className="sideNav">{sideMenu?.map((val) => val.element)}</ul>;
};
export default Sidebar;
