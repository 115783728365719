import Button from "App/Components/UI/Button";
import ButtonAction from "App/Components/UI/ButtonAction";
import Pagination from "App/Components/UI/Pagination";
import SearchFilter from "App/Components/UI/SearchFilter";
import Table from "App/Components/UI/Table";
import TableHeader from "App/Components/UI/Table/Components/TableHeader";
import tableSort from "App/helpers/tableSort";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ActionButton from "Assets/Images/ActionButton.svg";
import wall from "Assets/Images/2Dwall.svg";
import MenuDel from "Assets/Images/menu-del.svg";
import MenuPen from "Assets/Images/menu-pen.svg";
import BackgroundWall from "Assets/Images/BackgroundWall.svg";
import "../../BackgroundWall.scss";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import TemplatePopup from "App/Components/UI/TemplatePopup";
import Image from "App/Components/UI/Image";
import ConfirmPopup from "App/Components/UI/ConfirmPopup";
import User from "App/Components/UI/User";
import { LIST_LIMIT } from "App/constants";
const paginationConfig = {
    perPage: LIST_LIMIT
}


const actionMenu = [{
    label: 'Edit',
    action: 'edit',
    src: MenuPen
},
{
    label: 'Delete',
    action: 'delete',
    src: MenuDel
}]


const Thumbnail = () => {
    const [initial, setInitial] = useState(true);
    const dispatch = useStore(false)[1];
    const { triggerAPI } = useHttp();
    const navigate = useNavigate();
    const [sort, setSort] = useState({ direction: 'ASC', sortKey: 'name' });
    const [page, setPage] = useState(1);
    const [list, setList] = useState();
    const [data, setData] = useState();
    const [totalPage, setTotalPage] = useState(1);
    const [ShowRemovePopup, setShowRemovePopup] = useState({ mode: false })
    const [viewCategory, setViewCategory] = useState({ mode: false });
    const [search, setSearch] = useState('')
    const [filteredData, setFilteredData] = useState('')

    const [wallMenu, setWallMenu] = useState([
        {
            label: 'Wall Color',
            action: 'wallColor',
            selected: false,
            popup: true,
            type: 'wallColor'
        },
        {
            label: 'Wall Style',
            action: 'wallStyle',
            selected: false,
            popup: true,
            type: 'wallStyle'
        },
        {
            label: 'Date Range',
            action: 'dateRange',
            selected: false,
            popup: true
        }
    ])
    const onFilterData = (e) => {
        setFilteredData(e)
    }
    const wallCreationHandler = () => {
        navigate(`/dashboard/background-wall/wall-multi/create`);
    }
    const onSortHandler = useCallback((sortKey) => {
        const dir = sort.sortKey === sortKey ? sort.direction : '';
        const direction = tableSort(dir)
        setSort({ direction, sortKey })
    }, [sort])

    const onCloseRemovePopup = (e) => {
        setShowRemovePopup({ mode: false })
        if (e) {
            deleteWallDetails()
        }
    }

    const onCloseViewCategoryDetails = () => {
        setViewCategory({ mode: false })
    }

    const onPageChangeHandler = (page) => {
        setPage(page)
                const root = document.getElementById('content');
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
    }
    const menuActionHandler = (e) => {
        setData(e.row)
        if (e.action === 'edit') {
            navigate(`/dashboard/background-wall/wall-multi/edit/${e?.row?.id}`);
        }
        else {
            setShowRemovePopup({ mode: true })
        }
    }

    const getWallResult = useCallback((res) => {
        dispatch('hideSpinner');
        const { list, pageMeta } = res.data
        setList(list)
        setTotalPage(pageMeta.totalItems)
    }, [dispatch])

    const getWallDetails = useCallback(() => {
        dispatch('showSpinner');
        const filter = {
            sortBy: sort.sortKey,
            orderBy: sort.direction,
            ...filteredData
        }
        const data = new URLSearchParams({
            page: page,
            items: paginationConfig.perPage
        })

        triggerAPI({
            url: `background-thumbnail/admin/get?${data}`, data: {
                page: page,
                items: paginationConfig.perPage,
                search: `${search}`, filter
            }, method: 'post'
        }, getWallResult);
    }, [dispatch, sort.sortKey, sort.direction, filteredData, page, triggerAPI, search, getWallResult])

    const apiResult = useCallback((res) => {
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
        getWallDetails()
    }, [dispatch, getWallDetails])

    const deleteWallDetails = useCallback(() => {
        dispatch('showSpinner');
        const deleteData = {
            id: data?.id
        }
        triggerAPI({
            url: `background-thumbnail/delete`, data: deleteData, method: 'delete'
        }, apiResult);
    }, [data?.id, dispatch, apiResult, triggerAPI])

    const viewHandler = (e) => {
        setData(e)
        setViewCategory({ mode: true })
    }
    const searchHandler = (e) => {
        setSearch(e)
    }

    useEffect(() => {
        if (page !== 1) {
            setPage(1)
        }
        else {
            getWallDetails();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort, search, filteredData])

    useEffect(() => {
        if (!initial) {
            getWallDetails();
        }
        setInitial(false) // for removing initial call
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])
    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <div className='search-filter'>
                    <SearchFilter position={{ right: 80, top: 10 }} onSearch={searchHandler} setActionMenu={setWallMenu} actionMenu={wallMenu} onFilterData={onFilterData} data={filteredData} />
                </div>
                <div className="flex justify-between items-center gap-4">
                    <div className="flex wall-total gap-1">
                        <img src={BackgroundWall} alt="wall" />
                        <div> TotalWalls: <span>{totalPage}</span></div>
                    </div>
                    <Button label="Add Wall" onClick={wallCreationHandler} />
                </div>
            </div>
            <div>
                <Table>
                    <thead className="text-left">
                        <tr>
                            <th>
                                <TableHeader label="Preview" />
                            </th>
                            <th>
                                <TableHeader label="Name" sort={sort} onSort={onSortHandler} sortKey="name" />
                            </th>
                            <th>
                                <TableHeader label="Color" sort={sort} onSort={onSortHandler} sortKey="color" />
                            </th>
                            <th>
                                <TableHeader label="Style" sort={sort} onSort={onSortHandler} sortKey="style" />
                            </th>

                            <th>
                                <div className="flex justify-end items-center">
                                    <TableHeader label="Actions" />
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {list?.map(wall2D => (
                            <tr className="wall-body" key={wall2D?.id}>
                                <td>
                                    <div className="flex wall-image">
                                        <img src={wall2D?.imageUrl256 ?? wall} alt="wallImage" />

                                        <div className="wall-eye ">
                                            <Button className={`btn btn-eye btn-eye-on`} row={wall2D} onClick={viewHandler} /></div>
                                    </div>
                                </td>
                                <td className="capitalize">
                                    {wall2D?.name}
                                </td>
                                <td className="capitalize">
                                    {wall2D?.color}
                                </td>
                                <td className="capitalize">
                                    {wall2D?.style}
                                </td>
                                <td>
                                    <div className="flex justify-end items-center" >
                                        <ButtonAction src={ActionButton} menu={actionMenu} row={wall2D} onAction={menuActionHandler} />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                {!list?.length &&
                    <div className="text-center no-rows pt-4">
                        <p>No data</p>
                    </div>
                }
                <div className="mt-6">
                    <Pagination itemsPerPage={paginationConfig.perPage} total={totalPage} rows={list?.length} onPageChange={onPageChangeHandler} selected={page} />
                </div>
            </div>

            <TemplatePopup show={viewCategory} title='Edit Category' cssClass="info-desk" style={{ 'maxWidth': '79.14rem' }}
                onClose={onCloseViewCategoryDetails}>
                <div className="p-8">
                    <div className="flex justify-end p-1 pb-2">
                        <button className="btn btn-closes" onClick={onCloseViewCategoryDetails}></button>
                    </div>
                    <div className="flex flex-col w-full wall-view">
                        <Image src={data?.imageUrl} alt="images" width="300px" height="300px" style={{ 'minWidth': '20rem' }} />
                        <span className="pt-4 flex justify-center items-center">{data?.name}</span>
                    </div>
                </div>
            </TemplatePopup>

            <ConfirmPopup show={ShowRemovePopup} content='Are you sure want to delete this wall?' buttonYes="Delete" buttonCancel="Cancel"
                style={{ 'maxWidth': '39rem' }}
                title="Delete Background Wall" cssClass="delete-user" onClose={onCloseRemovePopup}>
                <div className="flex block-user-card">
                    <User src={data?.imageUrl} label={data?.name} className="delete-wall" />
                </div>
            </ConfirmPopup>
        </div>
    )
}
export default Thumbnail;