import { useStore } from "App/hooks-store/store";
import { useEffect } from "react";
import { Outlet } from "react-router-dom"

const InformationDeskLayout = () => {
    const dispatch = useStore(false)[1];

    useEffect(() => {
        dispatch('setPageTitle', 'Information Desk')
    }, [dispatch])

    return (
        <Outlet />
    )

}

export default InformationDeskLayout