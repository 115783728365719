import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonLoader = (props) => {
    const defaultConfig = {
        baseColor: "var(--cu-black-900)",
        highlightColor: "var(--cu-black-20)",
        ...props
    }
    return (
        < Skeleton {...defaultConfig} />
    )
}
export default SkeletonLoader;