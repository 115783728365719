import { useStore } from "App/hooks-store/store";
import { useCallback, useEffect, useState } from "react";

import "./SubscriptionPlan.scss";
import Button from "App/Components/UI/Button";
import SubscriptionList from "./components/SubscriptionList";
import useHttp from "App/hooks/use-http";
import Popup from "App/Components/UI/Popup";
import SubscriptionModal from "./components/SubscriptionModal";
import ConfirmPopup from "App/Components/UI/ConfirmPopup";

const CollectorsPlan = () => {
  const { triggerAPI } = useHttp();
  const [list, setList] = useState([]);
  const [showCreateSubAdminPopup, setShowCreateSubAdminPopup] = useState({
    mode: false,
  });
  const [selectedData, setSelectedData] = useState({});
  const [showActivatePop, setShowActivatePop] = useState({ mode: false });

  const activateHandler = () => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `subscription/${selectedData?.id}/remove`,
        data: {},
        method: "delete",
      },
      apiResult,
      (err) => {
        dispatch("hideSpinner");
        dispatch("showToast", {
          toast: {
            toastMode: "error",
            message:
              err?.response?.data?.error?.message ||
              `Failed to delete subscription plan`,
          },
        });
      }
    );
  };

  const apiResult = (res) => {
    const { message } = res;
    dispatch("hideSpinner");
    dispatch("showToast", { toast: { toastMode: "success", message } });
    if (showActivatePop?.mode) setShowActivatePop({ mode: false });
    getSubscriptionPlans();
  };

  const onCloseActivate = (data) => {
    if (data) {
      activateHandler();
    } else {
      setShowActivatePop({ mode: false });
    }
  };

  const handleAdd = (data) => {
    setSelectedData(data);
    setShowCreateSubAdminPopup({ mode: true });
  };

  const handleDelete = (data) => {
    setSelectedData(data);
    setShowActivatePop({ mode: true });
  };

  const onCloseCreateSubAdmin = (data) => {
    setShowCreateSubAdminPopup({ mode: false });
    if (data) {
      getSubscriptionPlans();
    }
  };

  const dispatch = useStore(false)[1];
  useEffect(() => {
    dispatch("setPageTitle", "Subscription Plans");
  }, [dispatch]);

  const getSubAdminResponse = useCallback(
    (res) => {
      dispatch("hideSpinner");
      const { data } = res;
      setList(data);
    },
    [dispatch]
  );

  const getSubscriptionPlans = useCallback(() => {
    triggerAPI(
      {
        url: `subscription/get`,

        method: "get",
      },
      getSubAdminResponse
    );
  }, []);

  useEffect(() => {
    dispatch("showSpinner");

    getSubscriptionPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="subscription-plan">
      <div className="flex justify-between items-center mb-4">
        <h4 className="title"></h4>
        <div>
          <Button label="Add new" onClick={handleAdd} />
        </div>
      </div>
      <div>
        {list.length ? (
          list.map((plan) => (
            <SubscriptionList
              plan={plan}
              handleAdd={handleAdd}
              handleDelete={handleDelete}
            />
          ))
        ) : (
          <p className="mt-6 text-center">
            Oops! There's no available subscription plans
          </p>
        )}
      </div>
      <ConfirmPopup
        row={selectedData}
        show={showActivatePop}
        content={`Are you sure you want to delete this plan, ${selectedData?.name}`}
        buttonYes="Yes"
        buttonCancel="No"
        style={{ maxWidth: "30rem" }}
        title={`Delete subscription plan`}
        cssClass="delete-user"
        onClose={onCloseActivate}
      ></ConfirmPopup>
      <Popup
        show={showCreateSubAdminPopup}
        style={{ maxWidth: "40rem" }}
        title={`${
          selectedData.id ? "Edit subcription Plan" : "Add subscription plan"
        }`}
        cssClass="user-create-pop"
        onClose={onCloseCreateSubAdmin}
      >
        <SubscriptionModal
          list={null}
          triggerClose={onCloseCreateSubAdmin}
          row={selectedData}
        />
      </Popup>
    </div>
  );
};

export default CollectorsPlan;
