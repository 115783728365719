import TableView from "../Components/TableView";

const Policy = () => {
    return (
        <>
            <TableView type={2} label="Add Privacy and Policy" deleteWarning="Privacy and Policy" />
        </>
    )
}

export default Policy;