import { useStore } from "App/hooks-store/store";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import "./LineItem.scss";



const LineItem = ({data, actionHandler}) => {
    return (
        <div className="monet-reward-lineitem">
            <div className="flex gap64">
                <div className="flex flex1 borderRightGrey fd-col">
                    <div>Registration Reward</div>
                    <div className="flex gap5">
                        <img src="/monet-sm-icon.8ecdda7d18cd8b2b6503c4ef02bcfa3b.svg" alt="icon" className="iconStyle"/>
                        <span>{data.rewardAmount}</span>
                    </div>
                </div>
                <div className="flex flex1 fd-col">
                    <div>{data.minDays} day(s)</div>
                    <div>{data.nftCount} NFT</div>
                </div>
                <div className="flex flex3 flex-end">
                    <button className="btnStyle" onClick={()=>actionHandler(data)}>Edit</button>
                </div>
            </div>
        </div>
    )
}
export default LineItem;