/* eslint-disable react-hooks/exhaustive-deps */
import ButtonAction from "App/Components/UI/ButtonAction";
import ConfirmPopup from "App/Components/UI/ConfirmPopup";
import Image from "App/Components/UI/Image";
import Pagination from "App/Components/UI/Pagination";
import SearchFilter from "App/Components/UI/SearchFilter";
import SocialMedia from "App/Components/UI/SocialMedia";
import Status from "App/Components/UI/Status";
import Table from "App/Components/UI/Table";
import TableHeader from "App/Components/UI/Table/Components/TableHeader";
import tableSort from "App/helpers/tableSort";
import { useCallback, useState } from "react";
import MenuPen from "Assets/Images/menu-pen.svg";
import MenuLink from "Assets/Images/menu-link.svg";
import { useNavigate } from "react-router-dom";
import ActionButton from "Assets/Images/ActionButton.svg";
import "./Table.scss";
import Button from "App/Components/UI/Button";
import Popup from "App/Components/UI/Popup";
import Upload from "../Upload";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import ToggleSwitch from "App/Components/UI/ToggleSwitch";

const actionBlockMenu = [
  {
    label: "Edit",
    action: "edit",
    src: MenuPen,
  },
  {
    label: "Block",
    action: "block",
    src: MenuLink,
  },
];
const actionMenu = [
  {
    label: "Edit",
    action: "edit",
    src: MenuPen,
  },
  {
    label: "Activate",
    action: "activate",
    src: MenuLink,
  },
];

const actionBlockActivateMenu = [
  {
    label: "Edit",
    action: "edit",
    src: MenuPen,
  },
  {
    label: "Activate",
    action: "activate",
    src: MenuLink,
  },
  {
    label: "Block",
    action: "block",
    src: MenuLink,
  },
];
const actionVerifyBlockMenu = [
  {
    label: "Edit",
    action: "edit",
    src: MenuPen,
  },
  {
    label: "Approve",
    action: "verify",
    src: MenuLink,
  },
  {
    label: "Reject",
    action: "reject",
    src: MenuLink,
  },
];


const CollectionListing = ({
  sort,
  setSort,
  activateHandler,
  page,
  showActivatePop,
  selectedData,
  totalPage,
  paginationConfig,
  setFilteredData,
  filteredData,
  setShowActivatePop,
  setSelectedData,
  setPage,
  list,
  type,
  onSearchChange,
  isTemporary,
  toggleSwitch=()=>{},
  getCollection=()=>{}
}) => {
  const navigate = useNavigate();
  const [actionStatus, setActionStatus] = useState("");
  const [showBulkUploadPopup, setBulkUploadPopup] = useState({
    mode: false,
  });
  const dispatch = useStore(false)[1];
  const { triggerAPI } = useHttp();
  const [collectionMenu, setCollectionMenu] = useState([
    {
      label: "Community",
      action: "community",
      selected: true,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Category",
      action: "category",
      selected: true,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Items",
      action: "items",
      selected: true,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Owners",
      action: "owners",
      selected: false,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Collection IDs",
      action: "collectionId",
      sort: true,
      sortKey: "collectionId",
      multiSelect: true,
      selected: false,
      type: "displayedFrames",
    },
    {
      label: "Announcements",
      action: "announcements",
      selected: false,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Events",
      action: "events",
      selected: false,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Description",
      action: "description",
      selected: false,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "About Us",
      action: "aboutus",
      selected: false,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Social Links",
      action: "sociallink",
      selected: false,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Blockchain",
      action: "blockchain",
      selected: false,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Home",
      action: "statusToggle",
      selected: type === 'collection',
      sort: type === 'collection',
      sortKey: "showOnHomePageDisplay",
      multiSelect: true,
      type: "statusToggle",
    },
    {
      label: "Created By",
      action: "by",
      selected: true,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Blockchain IDs",
      action: "blockchainId",
      selected: false,
      multiSelect: true,
      type: "displayedFrames",
    },
  ]);
  
  const onSortHandler = useCallback(
    (sortKey) => {
      const dir = sort.sortKey === sortKey ? sort.direction : "";
      const direction = tableSort(dir);
      setSort({ direction, sortKey });
    },
    [sort]
  );
  const onCloseActivate = (data) => {
    if (data) {
      activateHandler(actionStatus);
    } else {
      setShowActivatePop({ mode: false });
    }
  };
  const onPageChangeHandler = (page) => {
    setPage(page);
            const root = document.getElementById('content');
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
  };
  const actionHandler = (e) => {
    setSelectedData(e.row);
    setActionStatus(e?.action);
    switch (e?.action) {
      case "edit":
        navigate(`details/${e?.row?.id}`, { state: { temporary: isTemporary } });
        return;
      case "verify":
        navigate(`/dashboard/collection-management/verify-collections/verification/${e?.row?.id}`, { state: { temporary: isTemporary } });
        return;
      case "block":
      case "reject":
      case "activate":
        return setShowActivatePop({ mode: true });
      default:
        return;
    }
  };
  const stopHandler = (e) => {
    e.stopPropagation();
  };
  const onFilterData = (e) => {
    setFilteredData(e);
  };
  const apiResult = (res) => {
    const { message } = res;
    dispatch("hideSpinner");
    getCollection()
    dispatch("showToast", { toast: { toastMode: "success", message } });
    if (showBulkUploadPopup?.mode) setBulkUploadPopup({ mode: false });
  };
  const submitBulkUpload = (data) => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/collection/bulk-upload`,
        data,
        method: "post",
      },
      apiResult,
      (err) => {
        dispatch("hideSpinner");
        dispatch("showToast", {
          toast: {
            toastMode: "error",
            message:
              err?.response?.data?.error?.message || "Bulk uploading failed",
          },
        });
      }
    );
  };

  const onCloseBulkUpload = () => {
    setBulkUploadPopup({ mode: false });
  };

  const bulkUpload = () => {
    setBulkUploadPopup({ mode: true });
  };
  const searchHandler = (e) => {
    onSearchChange(e)
  }
  
  return (
    <>
      <div className="collection">
        <div className="flex justify-between items-center mb-4">
          
          <div className="search-filter">
              <div>
                <SearchFilter
                  position={{ right: 150, top: 10 }}
                  data={filteredData}
                  onSearch={searchHandler}
                  // noSearch={true}
                  className="lengthy"
                  setActionMenu={setCollectionMenu}
                  actionMenu={collectionMenu}
                  onFilterData={onFilterData}
                  apply={true}
                />
              </div>  
      

          </div>
          {!!(type === "collection") && (
            <div>
              <Button label="Bulk Upload" onClick={bulkUpload} />
            </div>
          )}
        </div>
        <div>
          <Table>
            <thead className="text-left">
              <tr>
                <th>
                  <TableHeader
                    label="Collection"
                    sort={sort}
                    onSort={onSortHandler}
                    sortKey="name"
                  />
                </th>
                {collectionMenu
                  ?.filter((el) => el?.selected)
                  ?.map((val) => (
                    <th key={val?.action}>
                      <TableHeader
                        label={val?.label}
                        sort={sort}
                        onSort={(key) => val?.sort && onSortHandler(key)}
                        sortKey={val?.sortKey ? val?.sortKey : ""}
                      />
                    </th>
                  ))}
                <th>
                  <TableHeader
                    label="Status"
                    sort={sort}
                    onSort={onSortHandler}
                    sortKey="status"
                  />
                </th>
                <th>
                  <div className="flex justify-end items-center">
                    <TableHeader label="Actions" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="gallery-body">
              {list?.map((value) => {
                let imagePreview = value?.filePath256;
                return (
                  <tr data-galleryid={value?.id} key={value.id}>
                    <td className="flex gap-3 items-center">
                      <div className="wall-image-gallery">
                        <Image
                          src={imagePreview ? imagePreview : ""}
                          className="gallery-image"
                          loaderClass="nft-drop-loader"
                          style={{ maxWidth: "4rem", Height: "4rem" }}
                        />
                      </div>
                      <span className="name-span">{value?.name}</span>
                    </td>
                    {!!collectionMenu?.find((val) => val?.label === "Community")
                      ?.selected && <td>{value?.community?.name}</td>}
                    {!!collectionMenu?.find((val) => val?.label === "Category")
                      ?.selected && <td>{value?.category?.name}</td>}                    
                    {!!collectionMenu?.find((val) => val?.label === "Items")
                      ?.selected && <td>{value?.items}</td>}
                    {!!collectionMenu?.find((val) => val?.label === "Owners")
                      ?.selected && <td>{value?.owners}</td>}
                    {!!collectionMenu?.find(
                      (val) => val?.label === "Collection IDs"
                    )?.selected && <td>{value?.collectionId}</td>}
                    {!!collectionMenu?.find(
                      (val) => val?.label === "Announcements"
                    )?.selected && <td>{value?.announcements}</td>}
                    {!!collectionMenu?.find((val) => val?.label === "Events")
                      ?.selected && <td>{value?.eventsCount}</td>}
                    {!!collectionMenu?.find(
                      (val) => val?.label === "Description"
                    )?.selected && <td>{value?.description}</td>}
                    {!!collectionMenu?.find((val) => val?.label === "About Us")
                      ?.selected && <td>{value?.community?.about}</td>}
                    {!!collectionMenu?.find(
                      (val) => val?.label === "Social Links"
                    )?.selected && (
                      <td>
                        <div className="flex gap-2">
                          {!!value?.website && (
                            <SocialMedia link={value?.website} type="website" />
                          )}
                          {!!value?.twitter && (
                            <SocialMedia link={value?.twitter} type="twitter" />
                          )}
                          {!!value?.discord && (
                            <SocialMedia link={value?.discord} type="discord" />
                          )}
                          {!!value?.instagram && (
                            <SocialMedia
                              link={value?.instagram}
                              type="instagram"
                            />
                          )}
                          {!!value?.youtube && (
                            <SocialMedia link={value?.youtube} type="youtube" />
                          )}
                          {!!value?.email && (
                            <SocialMedia link={value?.email} type="email" />
                          )}
                        </div>
                      </td>
                    )}
                    {!!collectionMenu?.find(
                      (val) => val?.label === "Blockchain"
                    )?.selected && <td>{value?.blockchainType?.name}</td>}
                    {!!collectionMenu?.find(
                      (val) => val?.label === "Home"
                    )?.selected && <td>
                        <div className="flex gallery-home justify-center items-center" onClick={stopHandler}>
                            {
                            (value?.community && value?.status === 'active') && 
                            <ToggleSwitch className="gallery-switch" check={value?.showOnHomePageDisplay} row={value} onChange={toggleSwitch} />}
                        </div>
                      </td>}
                    {!!collectionMenu?.find(
                      (val) => val?.label === "Created By"
                    )?.selected && (
                      <td>{value?.user?.name}</td>
                    )}
                    {!!collectionMenu?.find(
                      (val) => val?.label === "Blockchain IDs"
                    )?.selected && <td>{value?.blockchainTypeId}</td>}
                    <td>
                      <Status
                        label={
                          value?.status === "blocked" ? "Blocked" : (value?.status === "inactive" ? "Inactive" : (value?.status === "ownership_requested" ? "Ownership Requested" : "Active"))
                        }
                      />
                    </td>
                    <td>
                      <div
                        className="flex justify-end items-center"
                        onClick={stopHandler}
                      >
                        <ButtonAction
                          row={value}
                          src={ActionButton}
                          menu={
                            value?.status === "blocked" ? actionMenu
                              : value?.status === "inactive"
                              ? actionBlockActivateMenu
                              : (value?.status === "ownership_requested") ? actionVerifyBlockMenu : actionBlockMenu
                          }
                          onAction={actionHandler}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {!list?.length && (
            <div className="text-center no-rows pt-4">
              <p>No collections found!</p>
            </div>
          )}
          <div className="mt-6">
            <Pagination
              itemsPerPage={paginationConfig.perPage}
              total={totalPage}
              rows={list?.length}
              onPageChange={onPageChangeHandler}
              selected={page}
            />
          </div>
        </div>
      </div>
      <ConfirmPopup
        row={selectedData}
        show={showActivatePop}
        content={`Are you sure want to ${
          ((selectedData?.status === "blocked" ||
          selectedData?.status === "inactive") && actionStatus === 'activate')
            ? "activate"
            : selectedData?.status === "ownership_requested" ? "reject" : "block"
        } this collection?`}
        buttonYes="Yes"
        buttonCancel="No"
        style={{ maxWidth: "30rem" }}
        title={`${
          ((selectedData?.status === "blocked" ||
          selectedData?.status === "inactive") && actionStatus === 'activate')
            ? "Activate"
            : selectedData?.status === "ownership_requested" ? "Reject" : "Block"
        } collection`}
        cssClass="delete-user"
        onClose={onCloseActivate}
      ></ConfirmPopup>
      <Popup
        show={showBulkUploadPopup}
        style={{ maxWidth: "40rem" }}
        title={`Bulk Upload`}
        cssClass="user-create-pop"
        onClose={onCloseBulkUpload}
      >
        <Upload triggerClose={onCloseBulkUpload} submit={submitBulkUpload} />
      </Popup>
    </>
  );
};

export default CollectionListing;
