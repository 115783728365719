import Button from "App/Components/UI/Button"
import Card from "App/Components/UI/Card";
import ImageUpload from "App/Components/UI/ImageUpload";
import { useNavigate } from "react-router-dom"
import "./Profile.scss";
import { useCallback, useEffect, useState } from "react";
import useHttp from "App/hooks/use-http";
import { useStore } from "App/hooks-store/store";

const Profile = () => {
    let navigate = useNavigate();

    const editProfileHandler = () => {
        navigate("edit")
    }
    const { triggerAPI } = useHttp();
    const dispatch = useStore(false)[1];
    const [list, setList] = useState([]);
    const getProfileResult = useCallback((res) => {
        dispatch('hideSpinner');
        const list = res.data;
        setList(list)
    }, [dispatch])

    const getProfileDetails = useCallback(() => {
        dispatch('showSpinner');
        triggerAPI({
            url: `admin/profile/get`, method: 'get'
        }, getProfileResult);
    }, [dispatch, getProfileResult, triggerAPI])

    useEffect(() => {
        getProfileDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <div className='settings-profile-title'>
                    <div>Profile</div>
                </div>
                <div>
                    <Button label="Edit Profile" onClick={editProfileHandler} />
                </div>
            </div>


            <div className="pt-4">
                <Card>
                    <div className="settings-profile-card">
                        <div className="settings-profile-image">
                            <ImageUpload src={list.profilePicUrl} className="profile-edit-image"/>
                        </div>
                        <div className="flex flex-wrap justify-between mt-6">
                            <div className="settings-profile-details">
                                <div className="gap-1 pb-5">
                                    <ul>Full Name
                                        <li>{list.name}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="settings-profile-details">
                                <div className="gap-1 pb-5">
                                    <ul>Email
                                        <li>{list.email}</li>
                                    </ul>

                                </div>
                            </div>
                            <div className="settings-profile-details">


                                <ul className="pt-5">
                                    Internal Payment Wallet Addresses
                                    <ul className="details">
                                        <li className="list-disc">Metamask
                                            <ul className="pt-2">
                                                Wallet Address
                                                <li>0xA530c5633b1a75319857043C137e1300a8A29636
                                                    <ul className="pt-2">
                                                        Wallet balance
                                                        <li>₳7343243444</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </ul>

                            </div>
                            <div className="settings-profile-details">




                                <ul className="details pt-5">

                                    <li className="list-disc pt-4">Venly
                                        <ul className="pt-2">
                                            Wallet Address
                                            <li>0xA530c5633b1a75319857043C137e1300a8A29636
                                                <ul className="pt-2">
                                                    Wallet balance
                                                    <li>₳7343243444</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </Card>

            </div>

        </div>
    )
}

export default Profile