import MonetIcon from "../../../../../../Assets/Images/monet_token.png";

function SubscriptionPriceItem({ name, planPrice, requiredMonetHolding }) {
  return (
    <div className="col-span-1 p-4 border-r">
      <div className="card-details">
        <p className="text-lg">{name}</p>
        <p>
          {planPrice > 0 ? (
            <>
              ${planPrice}
              <span className=" text-gray-500">{"/year"}</span>
            </>
          ) : (
            "Free"
          )}
        </p>
        <p>
          {requiredMonetHolding > 0 ? (
            <div className="flex items-center gap-1">
              <span className=" text-gray-500">{"Hold "}</span>{" "}
              <img src={MonetIcon} height={20} width={20} />
              {requiredMonetHolding.toLocaleString()}{" "}
            </div>
          ) : (
            "Free"
          )}
        </p>
      </div>
    </div>
  );
}

export default SubscriptionPriceItem;
