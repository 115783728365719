import InputBox from "App/Components/UI/Forms/InputBox";
import Validators from "App/Components/UI/Forms/Validators";
import { getInitial, markAsTouched, validateFormGroup } from "App/helpers/formValidation";
import useHttp from "App/hooks/use-http";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "App/Components/UI/Button";
import BreadCrumbs from "App/Components/UI/BreadCrumbs";
import { useNavigate } from "react-router-dom";
import DateTimePicker from "App/Components/UI/DateTimePicker";
import { useStore } from "App/hooks-store/store";
import "./AddProject.scss"
import SelectBox from "App/Components/UI/SelectBox";


const AddProject = () => {
    const currentYear = new Date().getFullYear();
    const { id } = useParams();
    const formGroup = useMemo(() => ({
        projectname: {
            value: undefined,
            isRequired: true
        },
        maxNftSupply: {
            value: undefined,
            isRequired: true
        },
        currency: {
            value: 1,
            isRequired: !!id
        },
        price: {
            value: undefined,
            isRequired: !!id
        },
        policyLocksDateTime: {
            value: new Date(`${currentYear}-12-31 23:59:59`),
            isRequired: !id
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [id])
    const [formValues, setFormvalues] = useState(getInitial(formGroup))
    const { triggerAPI } = useHttp();
    const [formVaildState, setFormVaildState] = useState({});
    const [isValid, setIsValid] = useState(false)
    const [touched, setTouched] = useState(false)
    const [dataList, setDataList] = useState();
    const [duplicateId, setDuplicateId] = useState(0);
    const dispatch = useStore(false)[1];
    const navigate = useNavigate();
    const currencyList = [{id:1, name: 'ADA', value: 'ada'}, {id: 2, name: 'USD', value: 'usd'}];
    const breadCrumbs = [
        {
            link: '/dashboard/project-management',
            label: 'Project Management'
        },
        {
            link: null,
            label: id ? 'Duplicate Project' : 'Create Project'
        }
    ]

    useEffect(()  => {
        if(id)
            setDuplicateId(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const inputOnChange = (e) => {
        setFormvalues((prev) => {
            return {
                ...prev,
                ...e,
            }
        })
    }
    
    const collectErrors = useCallback((error) => {
        setFormVaildState((prev) => {
            return {
                ...prev,
                ...error
            }
        })
    }, [])

    useEffect(() => {
        if (touched) {
            const newValues = markAsTouched(formValues);
            setFormvalues(newValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [touched])

    useEffect(() => {
        const { valid } = validateFormGroup({ errors: formVaildState, formGroup, values: formValues });
        setDataList(formValues)
        setIsValid(valid)
    }, [formValues, formVaildState, formGroup])
    const goToProject = () => {
        navigate("/dashboard/project-management")
    }
    const saveHandler = (data) => {
        if (isValid) {     
            dispatch('showSpinner');
            if(duplicateId) {
                delete data?.policyLocksDateTime;
                delete data?.payoutWalletaddress;
                data.projectId = duplicateId;
                data.currency = currencyList?.find(item => item?.id=== data.currency)?.value;
                triggerAPI({
                    url: 'nft-maker/project/duplicate', data: data, method: 'post'
                }, apiResult);
            } else {                
                data.currency = currencyList?.find(item => item?.id=== data.currency)?.value;
                triggerAPI({
                    url: 'nft-maker/project/create', data: data, method: 'post'
                }, apiResult);
            }            
        }
        else {
            setTouched(true)
        }
    }
    const apiResult = (res) => {
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
        navigate("/dashboard/project-management");
    }
    return (
        <div>
            <div className="flex justify-between">
                <div className="back">
                    <BreadCrumbs links={breadCrumbs} title={id ? 'Duplicate Project' : 'Create Project'} className="frame" />
                </div>
                <div className="flex gap-3">
                    <Button label="Cancel" className="transaction-cancel" onClick={goToProject} />
                    <Button label="Save" row={dataList} onClick={saveHandler} />
                </div>
            </div>
            <div className="pt-6">
                <div className="">
                    <div className="add-project px-8 py-9">
                        <div className="flex gap-[2rem] justify-between flex-1">
                            <div className="w-full">
                                <InputBox label="Project Name" type="text" validateOnBlur controlName="projectname" className="" onChange={inputOnChange} value={formValues.projectname} />
                                <Validators type="Required" value={formValues.projectname} message="Required field" controlName="projectname" onErrors={collectErrors} />
                            </div>
                            <div className="w-full">
                                <InputBox label="NFT Supply" type="number" validateOnBlur controlName="maxNftSupply" className="frame-form" onChange={inputOnChange} value={formValues.maxNftSupply} />
                                <Validators type="Required" value={formValues.maxNftSupply} message="Required field" controlName="maxNftSupply" onErrors={collectErrors} />
                            </div>
                            <div className="">
                                <SelectBox
                                    validateOnBlur
                                    required={false}
                                    list={currencyList}
                                    placeHolder="Select"
                                    label="Currency"
                                    keyName={"id"}
                                    className="frame-form"
                                    controlName="currency"
                                    onChange={inputOnChange}
                                    currentId={formValues.currency}
                                    tabIndex={0}
                                />
                            </div>
                            <div className="w-full">
                                <InputBox label="Price" type="number" validateOnBlur controlName="price" className="frame-form" onChange={inputOnChange} value={formValues.price} />
                                <Validators type="Required" value={formValues.price} message="Required field" controlName="price" onErrors={collectErrors} />
                            </div>
                            {!duplicateId &&              
                                <div className="w-full project-form-datepicker">
                                    <label className="form-label">Expire Date *</label>
                                    <DateTimePicker controlName='policyLocksDateTime' onChange={inputOnChange} value={formValues.policyLocksDateTime} />
                                    <Validators type="Required" value={formValues.policyLocksDateTime} message="Required field" controlName="policyLocksDateTime" onErrors={collectErrors} />
                                </div>
                            }       
                        </div>
                             
                    </div>

                </div>
            </div>
        </div>
        
    )
}
export default AddProject;