import { useStore } from "App/hooks-store/store";
import { Outlet } from "react-router-dom";
import RegistrationReward from "./Pages/RegistrationRewards";
import { useCallback, useEffect, useState } from "react";
import useHttp from "App/hooks/use-http";

const MonetReward = () => {


    return (
        <div>
            <div className="py-4">
              <div>
                    <RegistrationReward />
              </div>
            </div>
        </div>
    )
}
export default MonetReward;