/* eslint-disable react-hooks/exhaustive-deps */
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import userAvatarHover from "Assets/Icons/avatar_hover.svg";
import "./EditCollection.scss";
import FormatField from "App/Components/UI/FormatField";
import ArrowLeft from "Assets/Images/pagination-left-active.svg";
import Button from "App/Components/UI/Button";
import ReloadIcon from 'Assets/Icons/ReloadIcon.svg';
import SkeletonLoader from "App/Components/UI/SkeletonLoader";

import {
  getInitial,
  markAsTouched,
  validateFormGroup,
} from "App/helpers/formValidation";
import { copyClipHandler, objectToFormdata } from "App/helpers/utilities";
import InputBox from "App/Components/UI/Forms/InputBox";
import Validators from "App/Components/UI/Forms/Validators";
import TextArea from "App/Components/UI/TextArea";
import SelectBox from "App/Components/UI/SelectBox";
import TermsTable from "../../Components/TermsTable";
import TermsConditions from "../../Components/TermsConditions";
import dummyImage from "Assets/Images/dummypic.svg";
import copyIcon from "Assets/Icons/copy_icon.svg";
import {
  handleDragEnter,
  handleDragLeave,
  handleDragOver,
  handleDrop,
} from "App/helpers/file";
import Popup from "App/Components/UI/Popup";

const EditCollection = () => {
  const formGroup = {
    name: {
      value: undefined,
      isRequired: false,
    },
    blockchainTypeId: {
      value: undefined,
      isRequired: true,
    },
    collectionId: {
      value: undefined,
      isRequired: true,
    },
    description: {
      value: undefined,
      isRequired: false,
    },
    categoryId: {
      value: undefined,
      isRequired: false,
    },
    firstSubcategoryId: {
      value: undefined,
      isRequired: false,
    },
    secondSubcategoryId: {
      value: undefined,
      isRequired: false,
    },
    communityId: {
      value: undefined,
      isRequired: false,
    },
    marketplace: {
      value: undefined,
      isRequired: false,
    },
    instagram: {
      value: undefined,
      isRequired: false,
    },
    twitter: {
      value: undefined,
      isRequired: false,
    },
    discord: {
      value: undefined,
      isRequired: false,
    },
    email: {
      value: undefined,
      isRequired: false,
    },
    youtube: {
      value: undefined,
      isRequired: false,
    },
    website: {
      value: undefined,
      isRequired: false,
    },
    termsId: {
      value: undefined,
      isRequired: false,
    },
    category: {
      value: undefined,
      isRequired: false,
    },
    firstSubcategory: {
      value: undefined,
      isRequired: false,
    },
    secondSubcategory: {
      value: undefined,
      isRequired: false,
    },
    file: {
      value: undefined,
      isRequired: false,
    },
    collectionStorageId: {
      value: undefined,
      isRequired: true,
    },
    collectionEditionsId: {
      value: undefined,
      isRequired: true,
    },
    collectionFormatIds: {
      value: [],
      isRequired: true,
    },
    collectionTypeIds: {
      value: [],
      isRequired: true,
    },
    collectionTagIds: {
      value: [],
      isRequired: false,
    },
  };
  const { id } = useParams();
  const { pathname, state } = useLocation()
  const isEditPage = !!id;
  const { triggerAPI } = useHttp();
  const dispatch = useStore(false)[1];
  const [details, setDetails] = useState({});
  const [imgList, setImgList] = useState([]);
  const [clickedImageIndex, setClickedImageIndex] = useState(-1);
  const [blockchainTypeList, setBlockchainTypeList] = useState([]);
  const [formValues, setFormvalues] = useState(getInitial(formGroup));
  const [formVaildState, setFormVaildState] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [touched, setTouched] = useState(false);
  const [collectionLoading, setCollectionLoading] = useState(false);
  const [displayPicture, setDisplayPicture] = useState(null);
  const [category, setCategory] = useState([]);
  const [termsList, setTermsList] = useState([]);
  const [firstSubCategory, setFirstSubCategory] = useState([]);
  const [secondSubCategory, setSecondSubCategory] = useState([]);
  const [communityList, setCommunityList] = useState([]);
  const [sampleAssetData, setSampleAssetData] = useState({});
  const [collectionStorageList, setCollectionStorageList] = useState([]);
  const [collectionEditionsList, setCollectionEditionsList] = useState([]);
  const [collectionFormatList, setCollectionFormatList] = useState([]);
  const [collectionTypeList, setCollectionTypeList] = useState([]);
  const [collectionTagList, setCollectionTagList] = useState([]);
  const isVerfifyPage = pathname?.includes('verification');
  const isTemporary = state?.temporary;
  const [dataFetched, setDataFetched] = useState({
    category: false,
    data: false,
    communityList: false
  });
  const profileWrapRef = useRef(null);
  const navigate = useNavigate();

  const getCategoryResponse = useCallback(
    (res) => {
      setCategory(res?.data?.list);
      setDataFetched((prev) => {
        return { ...prev, category: true };
      });
    },
    [dispatch]
  );
  const getCategoryList = useCallback(() => {
    triggerAPI(
      {
        url: `category/get`,
        data: {
          page: 1,
          items: 1000,
          filter: {},
          search: "",
        },
        method: "post",
      },
      getCategoryResponse
    );
  }, [dispatch, getCategoryResponse, triggerAPI]);

  const getTermsResponse = useCallback(
    (res) => {
      setTermsList(res?.data?.list.sort((a, b) => a.id - b.id));
    },
    [dispatch]
  );

  const getTermsList = useCallback(() => {
    triggerAPI(
      {
        url: `community/collection-terms/get`,
        data: {
          page: 1,
          items: 100,
          filter: {},
          search: "",
        },
        method: "post",
      },
      getTermsResponse
    );
  }, [dispatch, getTermsResponse, triggerAPI]);

  const getCollectionStorageResponse = useCallback(
    (res) => {
      dispatch("hideSpinner");
      setCollectionStorageList(res?.data?.list.sort((a,b) => a.displayOrder > b.displayOrder ? 1:-1));
    },
    [dispatch]
  );
  
  const getCollectionStorage = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/collection_storage/get`,
        method: "get",
      },
      getCollectionStorageResponse
    );
  }, [dispatch, getCollectionStorageResponse, triggerAPI]);

  const getCollectionTypeResponse = useCallback(
    (res) => {
      dispatch("hideSpinner");
      setCollectionTypeList(res?.data?.list.sort((a,b) => a.displayOrder > b.displayOrder ? 1:-1));
    },
    [dispatch]
  );
  
  const getCollectionType = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/collection_type/get`,
        method: "get",
      },
      getCollectionTypeResponse
    );
  }, [dispatch, getCollectionTypeResponse, triggerAPI]);

  const getCollectionTagResponse = useCallback(
    (res) => {
      dispatch("hideSpinner");
      setCollectionTagList(res?.data?.list.sort((a,b) => a.name > b.name ? 1:-1));
    },
    [dispatch]
  );
  
  const getCollectionTag = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/collection_tag/get`,
        method: "get",
      },
      getCollectionTagResponse
    );
  }, [dispatch, getCollectionTagResponse, triggerAPI]);

  const getCollectionFormatResponse = useCallback(
    (res) => {
      dispatch("hideSpinner");      
      setCollectionFormatList(res?.data?.list.sort((a,b) => a.displayOrder > b.displayOrder ? 1:-1));
    },
    [dispatch]
  );
  
  const getCollectionFormat = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/collection_format/get`,
        method: "get",
      },
      getCollectionFormatResponse
    );
  }, [dispatch, getCollectionFormatResponse, triggerAPI]);

  const getCollectionEditionsResponse = useCallback(
    (res) => {
      dispatch("hideSpinner");
      setCollectionEditionsList(res?.data?.list.sort((a,b) => a.displayOrder > b.displayOrder ? 1:-1));
    },
    [dispatch]
  );
  
  const getCollectionEditions = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/collection_editions/get`,
        method: "get",
      },
      getCollectionEditionsResponse
    );
  }, [dispatch, getCollectionEditionsResponse, triggerAPI]);

  const getBlockChainTypeResponse = useCallback(
    (res) => {
      dispatch("hideSpinner");
      const list = res?.data?.rows?.map((item) => {
        return { name: item?.name, id: item?.id, value: item?.id };
      });
      setBlockchainTypeList(list);
    },
    [dispatch]
  );

  const getBlockChainType = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/blockchain-types/get`,
        method: "get",
      },
      getBlockChainTypeResponse
    );
  }, [dispatch, getBlockChainTypeResponse, triggerAPI]);

  useEffect(() => {
    getCategoryList();
    getTermsList();
    getCollectionStorage();
    getCollectionEditions();
    getCollectionFormat();
    getCollectionType();
    getCollectionTag();
    getBlockChainType();
  }, []);

  const subCategoryHandler = useCallback( async (cId) => {
      let categoryTemp = category;
      const subCatgry = (await categoryTemp?.find((val) => val?.id === cId)?.subcategories) || [];
      setFirstSubCategory(subCatgry);
      setSecondSubCategory([]);
      return subCatgry;
  }, [category] );

  const secondSubCategoryHandler = useCallback(
    async (cId, keyName = "id", subCat) => {
      let subArr = subCat ?? firstSubCategory;
      const secondSub =
        (await subArr?.find((val) => val[keyName] === cId)
          ?.subcategories) || [];
      setSecondSubCategory(secondSub);
      return secondSub;
    },
    [firstSubCategory]
  );
  useEffect(() => {
    if (dataFetched?.data && dataFetched?.category) {
      updateHandler(formValues);
    }      
  }, [dataFetched]);
  
  const updateHandler = useCallback(async (val) => {
    let data = val ?? formValues;
    const subCategory = await subCategoryHandler(data?.categoryId);
      const categoryVal = category?.find(
        (val) => val?.id === data?.categoryId
      )?.name;
      const firstSubcategoryVal = subCategory?.find(
        (val) => val?.id === data?.firstSubcategoryId
      )?.name;
      const secondSubCategory = await secondSubCategoryHandler(data?.firstSubcategoryId,
        undefined,
        subCategory
      );      
      const secondSubcategoryVal = secondSubCategory?.find(
        (val) => val?.id === data?.secondSubcategoryId
      )?.name;
      const cateData = {
        category: categoryVal,
        firstSubcategory: firstSubcategoryVal,
        secondSubcategory: secondSubcategoryVal,
      };
    setFormvalues((prev) => {
      return { ...prev, ...cateData };
    });
  },[
    category,
      formValues?.categoryId,
      formValues?.firstSubcategoryId,
      formValues?.secondSubcategoryId,
  ]);

  // useEffect(() => {
  //   if (
  //     dataFetched?.category &&
  //     dataFetched?.data &&
  //     category &&
  //     formValues?.categoryId
  //   ) {
  //     updateHandler();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   dataFetched,
  //   category,
  //   formValues?.categoryId,
  //   formValues?.firstSubcategoryId,
  //   formValues?.secondSubcategoryId,
  //   formValues?.category,
  //   formValues?.firstSubcategory,
  //   formValues?.secondSubcategory,
  // ]);

  useEffect(() => {
    if (touched) {
      const newValues = markAsTouched(formValues);
      setFormvalues(newValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touched]);

  useEffect(() => {
    const { valid } = validateFormGroup({
      errors: formVaildState,
      formGroup,
      values: formValues,
    });
    setIsValid(valid);
  }, [formValues, formVaildState, formGroup]);

  const collectErrors = useCallback((error) => {
    setFormVaildState((prev) => {
      return {
        ...prev,
        ...error,
      };
    });
  }, []);
  const inputOnChange = (e) => {
    setFormvalues((prev) => {
      return {
        ...prev,
        ...e,
      };
    });
    if (Object.keys(e)[0] === "categoryId") {
      subCategoryHandler(e?.categoryId);
    } else if (Object.keys(e)[0] === "firstSubcategoryId") {
      secondSubCategoryHandler(e?.firstSubcategoryId);
    }
  };
  const getUpdateResult = useCallback(
    (res) => {
      dispatch("hideSpinner");
      dispatch("showToast", {
        toast: { toastMode: "success", message: res?.message },
      });
      gotToBack();
    },
    [dispatch, navigate]
  );

  const actionHandler = (list) => {
    const postUrl = isTemporary ? `community/collection/temp/update/${id}` : `community/collection/update/${id}`;
    const method = `put`;
    delete list.category;
    delete list.firstSubcategory;
    delete list.secondSubcategory;
    if (typeof list.file === "string") delete list.file;
    if (list.communityId === 0) delete list.communityId;

    const formData = objectToFormdata(list);
    triggerAPI(
      {
        url: postUrl,
        method: method,
        data: formData,
      },
      getUpdateResult
    );
  };

  const actionHandlerNew = (list) => {
    const postUrl = isTemporary ? `community/collection/temp/update/${id}` : `community/collection/update/${id}`;
    const method = `put`;
    delete list.category;
    delete list.firstSubcategory;
    delete list.secondSubcategory;
    if (typeof list.file === "string") delete list.file;
    if (list.communityId === 0) delete list.communityId;

    const formData = objectToFormdata(list);
    triggerAPI(
      {
        url: postUrl,
        method: method,
        data: formData,
      },
      triggerAPI(
        {
          url: isTemporary ? 'community/collection/temp/update-status' : 'community/collection/update-status',
          data: {
            id,
            status: `active`,
          },
          method: "patch",
        },
        getUpdateResult,
      ),
    );
  }

  const saveHandler = useCallback(() => {
    if (isValid) {
      dispatch("showSpinner");
      const list = { ...formValues };
      delete list.newtag;
      if(isVerfifyPage) {
        actionHandlerNew(list);
      } else {
        actionHandler(list);
      }
      // if (
      //   (list?.category && !list?.categoryId) ||
      //   (list?.firstSubcategory && !list?.firstSubcategoryId) ||
      //   (list?.secondSubcategory && !list?.secondSubcategoryId)
      // ) {
      //   if (list?.category && !list?.categoryId) {
      //     triggerAPI(
      //       {
      //         url: `category/create`,
      //         data: { name: list?.category },
      //         method: "post",
      //       },
      //       (res) => {
      //         list.categoryId = res?.data?.id;
      //         inputOnChange({ categoryId: res?.data?.id });
      //         createSubCategoryHandler(list);
      //       }
      //     );
      //   } else {
      //     createSubCategoryHandler(list);
      //   }
      // } else {
      //   actionHandler(list);
      // }
    } else {
      setTouched(true);
    }
  }, [dispatch, formValues, getUpdateResult, isValid, triggerAPI]);

  const imageHandle = (imgFile) => {
    if (imgFile) {
      const file = imgFile?.displayPicture;
      const fileSize = file.size / 1024 / 1024;
      const extension = file?.name.split(".").pop();
      const fileExtension = extension.toLowerCase();
      if (
        fileExtension === "png" ||
        fileExtension === "jpeg" ||
        fileExtension === "jpg" ||
        fileExtension === "gif" ||
        fileExtension === "webp" ||
        fileExtension === "svg"
      ) {
        if (fileSize <= 2) {
          const imageUrl = URL.createObjectURL(file);
          if (imgFile?.displayPicture) {
            inputOnChange({ file: file });
            setDisplayPicture(imageUrl);
          }
        } else {
          dispatch("showToast", {
            toast: {
              toastMode: "error",
              message: "The maximum size of an image should be 2 MB",
            },
          });
        }
      } else {
        dispatch("showToast", {
          toast: {
            toastMode: "error",
            message: "Image format should be png, jpeg, jpg, gif, svg or webp",
          },
        });
      }
    }
  };
  const handleCollectionStorage = (e) => {
    const id = e?.currentTarget?.dataset?.id;
    let ids;
    if (formValues?.collectionStorageId === Number(id)) {
      ids = null;
    } else {
      ids = Number(id);
    }
    inputOnChange({ collectionStorageId: ids });
  };
  const handleCollectionEdition = (e) => {
    const id = e?.currentTarget?.dataset?.id;
    let ids;
    if (formValues?.collectionEditionsId === Number(id)) {
      ids = null;
    } else {
      ids = Number(id);
    }
    inputOnChange({ collectionEditionsId: ids });
  };
  const handleCollectionFormat = (e) => {
    const id = e?.currentTarget?.dataset?.id;
    let ids = [...formValues?.collectionFormatIds];
    if (ids.includes(Number(id))) {
      ids.splice(formValues?.collectionFormatIds.indexOf(Number(id)),1);
    } else {
      ids.push(Number(id));      
    }    
    inputOnChange({ collectionFormatIds: ids });
  };
  const handleCollectionType = (e) => {
    const id = e?.currentTarget?.dataset?.id;
    let ids = [...formValues?.collectionTypeIds];
    if (ids.includes(Number(id))) {
      ids.splice(ids.indexOf(Number(id)),1);
    } else {
      ids.push(Number(id));      
    }
    inputOnChange({ collectionTypeIds: ids });
  };
  const handleCollectionTag = (e) => {
    const id = e?.currentTarget?.dataset?.id;
    let ids = [...formValues?.collectionTagIds];
    if (ids.includes(Number(id))) {
      ids.splice(ids.indexOf(Number(id)),1);
    } else {
      ids.push(Number(id));      
    }
    inputOnChange({ collectionTagIds: ids });
  };
  
  const saveNewTagResult = useCallback(
    (res) => {
      console.log(res);
      dispatch("hideSpinner");
      getCollectionTag();
      inputOnChange({ newtag: "" });      
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const saveNewTag = useCallback(
    (data) => {
      dispatch("showSpinner");
      triggerAPI(
        {
          url: "community/collection_tag/create",
          data,
          method: "post",
        },
        saveNewTagResult,
        (err) => {
          dispatch("hideSpinner");
          dispatch("showToast", {
            toast: { toastMode: "error", message: "Tag creation failed" },
          });
        }
      );
    },
    [dispatch, saveNewTagResult, triggerAPI]
  );

  const handleNewCollectionTag = (e) => {    
    let data = { name: formValues.newtag }
    saveNewTag(data);
  };
  const handleTerms = (e) => {
    if(!isEditPage) {
      const id = e?.currentTarget?.dataset?.id;
      let value = parseInt(id);
      if (formValues?.termsId === value) {
        value = null;
      }
      inputOnChange({ termsId: value });
    }   
  };
  console.log(termsList)
  useEffect(() => {
    if (id) {
      getDetailedCollection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const getDetailedCollectionResult = useCallback(
    (res) => {
      const { data } = res;
      let newDetails = {
        name: data?.name ?? "",
        description: data?.description ?? "",
        blockchainTypeId: data?.blockchainTypeId,
        collectionId: data?.collectionId,
        website: data?.website ?? "",
        twitter: data?.twitter ?? "",
        instagram: data?.instagram ?? "",
        discord: data?.discord ?? "",
        youtube: data?.youtube ?? "",
        email: data?.email ?? "",
        communityId: data?.communityId ?? 0,
        categoryId: data?.categoryId ?? "",
        marketplace: data?.marketplace ?? "",
        firstSubcategoryId: data?.firstSubcategoryId ?? "",
        secondSubcategoryId: data?.secondSubcategoryId ?? "",
        collectionStorageId: data?.collectionStorageId,
        collectionEditionsId: data?.collectionEditionsId,
        collectionFormatIds: data?.formats?.map(f=>f.id),
        collectionTypeIds: data?.types?.map(t=>t.id),
        collectionTagIds: data?.tags?.map(t=>t.id),
        termsId: data?.termsId ?? "",
        file: data?.filePath ?? "",
      }
      setFormvalues(newDetails);
      setDataFetched((prev) => {
        return { ...prev, data: true };
      });
      setDisplayPicture(data?.filePath);
      data.mintDate = dateValidate(data.mintDate);
      data.lockDate = dateValidate(data.lockDate);
      setDetails(data);
      let assets = data?.assets?.slice(1, 5); //don't include the first asset, its usually not an actual NFT
      if(assets?.length) {
          setSampleAssetData(assets[0] ?? {});
      }
      const imgApiResult = (assetId) => {
        return new Promise((resolve) => {
          triggerAPI(
            {
              url: `user/nft-collection/asset/detail?asset=${
                data?.collectionId + assetId
              }&networkType=MAINNET`,
              method: "get",
            },
            resolve
          );
        });
      };
      const promiseList = assets?.map((val) => imgApiResult(val?.asset_name));
      promiseList?.length &&
        Promise.all(promiseList).then((res) => {
          let imgs = res?.map((val) => {
            return {
              ...val?.data,
              image: val?.data?.onchain_metadata?.image
                ? `https://cyeepyhsfa.cloudimg.io/https://ipfs.io/ipfs/` +
                  val?.data?.onchain_metadata?.image?.replace("ipfs://", "")
                : null,
            };
          });
          setImgList(imgs);
        });
      dispatch("hideSpinner");
    },
    [dispatch]
  );
  const dateValidate = useCallback((date) => {
    const dateValue = new Date(date);
    if (isNaN(dateValue)) {
      return '';
    } else {
      return dateValue.toISOString();
    }
  }, [])
  const getDetailedCollection = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: isTemporary ? 'community/collection/temp/get-one' : 'community/collection/get-one',
        data: {
          id: Number(id),
        },
        method: "post",
      },
      getDetailedCollectionResult
    );
  }, [dispatch, getDetailedCollectionResult, triggerAPI, id]);
  useEffect(() => {
    if (details?.createdBy || details?.user?.id) getCommunityList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details?.createdBy, details?.user?.id]);

  useEffect(() => {
    if(dataFetched?.communityList && dataFetched?.data) {
      const tempList = communityList?.filter((list => list?.id === formValues?.communityId))
      setCommunityList(tempList);
    }
  }, [dataFetched]);
  const getCommunityListResult = useCallback(
    (res) => {
      const { data } = res;
      setCommunityList(data?.list);
      setDataFetched((prev) => {
        return { ...prev, communityList: true };
      });
    },
    [dispatch]
  );
  const getCommunityList = useCallback(() => {
    triggerAPI(
      {
        url: `community/get`,
        method: "post",
        data: {
          page: 1,
          items: 100,
          filter: {},
          search: "",
        },
      },
      getCommunityListResult
    );
  }, [
    dispatch,
    getCommunityListResult,
    triggerAPI,
    details?.createdBy,
    details?.user?.id,
  ]);

  const gotToBack = () => {
    navigate(-1);
  };

  const copytoClip = (e) => {
    const { id } = e.currentTarget.dataset;
    copyClipHandler(id, (res) => {
      res &&
        dispatch("showToast", {
          toast: { toastMode: "success", message: "Copied to clipboard" },
        });
    });
  };

  const imageClicked = (i) => {
    setClickedImageIndex(i);
  };
  const handleCommunity = (e) => {
    const id = e?.currentTarget?.dataset?.id;
    let ids;
    if (formValues?.communityId === Number(id)) {
      ids = null;
    } else {
      ids = Number(id);
    }
    inputOnChange({ communityId: ids });
  };
  const reFetchCollectionResult = useCallback((res) => {
    setCollectionLoading(false)
    const { data } = res;
    setDetails(prev => {return {...prev, 
      ownersCount: data?.ownerCount,
      mintDate: dateValidate(data?.mintDate),
      lockDate: dateValidate(data?.lockDate),
      items: data?.assetCount,
    }});
  },[]);
  const reFetchCollectionData = () => {
    setCollectionLoading(true)
    triggerAPI(
      {
        url: `user/nft-collection/collectiondata`,
        method: "post",
        data: {
          id,
          networkType: "MAINNET",
          assetName: sampleAssetData?.asset_name
        }
      },
      reFetchCollectionResult
    );
  }
  return (
    <div className="edit-collection mb-3">
      <div>
        <div className="flex gap-2 items-center">
          <img
            className="breadcrumbs-img"
            src={ArrowLeft}
            alt="img"
            onClick={gotToBack}
          />
          <h2>{details?.name}</h2>
        </div>
        <div className="banner flex gap-2 mt-3">
          <div className="image-section grid gap-2">
            {!!imgList?.length >= 4 && <img src={imgList[3]?.image} alt="" />}
            {imgList?.map((val, i) => (
              <div key={val?.asset} className="img-box" onClick={() => imageClicked(i)}>
                {!!val?.image && <img src={val?.image} alt="" />}
                <Popup
                  onClose={()=> setClickedImageIndex(-1)}
                  show={{ mode: clickedImageIndex === i }}
                  title="Metadata"
                >
                  <div>
                    <div className="content">
                      {JSON.stringify(val, null, 4)}
                    </div>
                  </div>
                </Popup>
              </div>
            ))}
            {[...Array(4 - imgList?.length)]?.map((val, i) => (
              <div key={val?.asset} className="img-box"></div>
            ))}
          </div>
          <div className="details-section">
            <div className="flex justify-end cursor-pointer reload-section">
              <img src={ReloadIcon} alt="" onClick={reFetchCollectionData} />
            </div>
            <div className="details-box">
              <p className="title">Collection</p>
              <p className="content">{details?.name}</p>
            </div>
            <div className="details-box">
              <p className="title">Blockchain</p>
              <p className="content">{details?.blockchainType?.name}</p>
            </div>
            <div className="details-box">
              <p className="title">Collection ID</p>
              {!!details?.collectionId && (
                <p className="content flex">
                  {details?.collectionId?.length < 11
                    ? details?.collectionId
                    : details?.collectionId?.slice(0, 4) +
                      "..." +
                      details?.collectionId?.slice(-4)}
                  <img
                    onClick={copytoClip}
                    data-id={details?.collectionId}
                    className="cursor-pointer w-4 ml-2.5"
                    alt="Img"
                    src={copyIcon}
                  />
                </p>
              )}
            </div>
            <div className="details-box">
              <p className="title">Items</p>
              <p className="content">{collectionLoading ? 
                <SkeletonLoader height={15} width={50} count={1} inline={true} /> : details?.items}</p>
            </div>
            <div className="details-box">
              <p className="title">Owners</p>
              <p className="content">{collectionLoading ? 
                <SkeletonLoader height={15} width={50} count={1} inline={true} /> : details?.ownersCount}</p>
                
            </div>
            <div className="details-box">
              <p className="title">Community</p>
              <p className="content">{details?.community?.name}</p>
            </div>
            <div className="details-box">
              <p className="title">Mint/Lock Date</p>
              <div className="content">
                {collectionLoading ? 
                <SkeletonLoader height={15} width={50} count={1} inline={true} /> : (details?.mintDate && (
                  <FormatField
                    type="dayMonthYearDate"
                    value={details?.mintDate}
                  />
                ))}
                /
                {collectionLoading ? 
                <SkeletonLoader height={15} width={50} count={1} inline={true} /> : (details?.lockDate && (
                  <FormatField
                    type="dayMonthYearDate"
                    value={details?.lockDate}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="community-form collection text-left flex flex-col gap-4 mt-4">
        <div className="flex flex-col gap-4">
          <div>
            <div className="section-title brd-btm w-full">Collection ID</div>
          </div>
          <div className="flex w-full gap-4">
            <div className="flex gap-4 w-full">
              <div className="flex w-full  flex-col gap-2">
                <div>
                  {/* <InputBox
                    maxlength={50}
                    label="Blockchain"
                    validateOnBlur
                    placeholder={"Cardano"}
                    type="text"
                    required={true}
                    onChange={inputOnChange}
                    controlName="blockchainTypeId"
                    value={formValues.blockchainTypeId}
                  /> */}
                  <SelectBox
                    validateOnBlur
                    list={blockchainTypeList}
                    required={false}
                    placeHolder="Select"
                    label="Blockchain"
                    keyName={"id"}
                    className="frame-form"
                    controlName="blockchainTypeId"
                    onChange={inputOnChange}
                    disabled={!!isEditPage}
                    currentId={formValues.blockchainTypeId}
                    tabIndex={0}
                  />
                  <Validators
                    type="Required"
                    value={formValues.blockchainTypeId}
                    message="Required field"
                    controlName="blockchainTypeId"
                    onErrors={collectErrors}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-4 w-full">
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <InputBox
                    type="text"
                    placeholder={"Collection ID"}
                    label="Collection ID"
                    validateOnBlur
                    required={true}
                    onChange={inputOnChange}
                    controlName="collectionId"
                    value={formValues.collectionId}
                    disabled={!!isEditPage}
                  />
                  <Validators
                    type="Required"
                    value={formValues.collectionId}
                    message="Required field"
                    controlName="collectionId"
                    onErrors={collectErrors}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <div className="section-title brd-btm w-full">
              Collection Details
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex gap-4">
              <div className="flex flex-col">
                <div className="flex flex-col gap-2 mt-3">
                  <div
                    className="upload-banner-image profile-img displayPictureCollection"
                    ref={profileWrapRef}
                    onDrop={(e) =>
                      handleDrop(
                        e,
                        profileWrapRef,
                        imageHandle,
                        "displayPicture",
                        "pro-pic"
                      )
                    }
                    id="pro-pic"
                    onDragOver={handleDragOver}
                    onDragEnter={(e) =>
                      handleDragEnter(e, profileWrapRef, "pro-pic")
                    }
                    onDragLeave={(e) =>
                      handleDragLeave(e, profileWrapRef, "pro-pic")
                    }
                  >
                    <div
                      className={"relative flex h-full flex-col justify-center"}
                    >
                      <label
                        className="cursor-pointer"
                        htmlFor="upload-button-displayPicture"
                      >
                        <img
                          alt="No Img"
                          src={userAvatarHover}
                          className={`upload-icon`}
                        ></img>
                        {!displayPicture && (
                          <div className="dummy-wrap">
                            <img
                              className={`image-uploaded`}
                              alt="NoImg"
                              src={dummyImage}
                            ></img>
                            <p>
                              Maximum file <br />
                              size 2MB <br />
                              Minimum size <br />
                              92px x 92px <br />
                              1:1
                            </p>
                          </div>
                        )}
                        {displayPicture && (
                          <img
                            alt="No Img"
                            className={`image-uploaded actual-img`}
                            src={displayPicture}
                          ></img>
                        )}
                      </label>
                      <input
                        type="file"
                        id="upload-button-displayPicture"
                        name={"displayPicture"}
                        className={"hidden"}
                        accept="image/*"
                        onChange={(e) =>
                          imageHandle({ displayPicture: e.target.files[0] })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-4 w-full">
                <div className="flex sm:flex-row flex-col gap-2 ">
                  <div className="w-full">
                    <InputBox
                      maxlength={35}
                      type="text"
                      label="Name"
                      validateOnBlur
                      required={true}
                      onChange={inputOnChange}
                      controlName="name"
                      value={formValues.name}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div>
                    <TextArea
                      placeholder={""}
                      label="Description"
                      validateOnBlur
                      type="text"
                      required={true}
                      rows={4}
                      onChange={inputOnChange}
                      maxLength={300}
                      showTextCount={true}
                      controlName="description"
                      value={formValues.description}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <div className="section-title brd-btm w-full">Categorization</div>
          </div>
          <div className="flex w-full gap-4">
            <div className="flex gap-4 w-full">
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <SelectBox
                    validateOnBlur
                    list={category}
                    required={false}
                    keyName={"id"}
                    placeHolder="Select"
                    label="Category"
                    className="frame-form"
                    controlName="categoryId"
                    onChange={inputOnChange}
                    currentId={formValues?.categoryId}
                    tabIndex={0}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-4 w-full">
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <SelectBox
                    validateOnBlur
                    list={[...firstSubCategory]}
                    required={false}
                    keyName={"id"}
                    placeHolder="Select"
                    label="Sub-Category-1"
                    className="frame-form"
                    controlName="firstSubcategoryId"
                    onChange={inputOnChange}
                    currentId={formValues?.firstSubcategoryId}
                    tabIndex={0}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-4 w-full">
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <SelectBox
                    validateOnBlur
                    list={[...secondSubCategory]}
                    required={false}
                    keyName={"id"}
                    placeHolder="Select"
                    label="Sub-Category-2"
                    className="frame-form"
                    controlName="secondSubcategoryId"
                    onChange={inputOnChange}
                    currentId={formValues?.secondSubcategoryId}
                    tabIndex={0}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
            <div className="upload-banner flex flex-col gap-2">
              <label>Formats (Choose all that apply)</label>
            </div>
            <div>
              <div className="flex flex-wrap sm:gap-[1rem] gap-[0.5rem] items-center new-checkbox">
                {collectionFormatList?.map((format) => (
                  <span
                    key={format?.id}
                    className={
                      formValues?.collectionFormatIds?.includes(format?.id) ? `active` : ""
                    }
                    data-id={format?.id}
                    onClick={handleCollectionFormat}
                  >
                    {format?.name}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
            <div className="upload-banner flex flex-col gap-2">
              <label>Types (Choose all that apply)</label>
            </div>
            <div>
              <div className="flex flex-wrap sm:gap-[1rem] gap-[0.5rem] items-center new-checkbox">
                {collectionTypeList?.map((type) => (
                  <span
                    key={type?.id}
                    className={
                      formValues?.collectionTypeIds?.includes(type?.id) ? `active` : ""
                    }
                    data-id={type?.id}
                    onClick={handleCollectionType}
                  >
                    {type?.name}
                  </span>
                ))}
              </div>                  
            </div>
          </div>
          <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
            <div className="upload-banner flex flex-col gap-2">
              <label>Storage</label>
            </div>
            <div>
              <div className="flex flex-wrap sm:gap-[1rem] gap-[0.5rem] items-center new-checkbox">
                {collectionStorageList?.map((storage) => (
                  <span
                    key={storage?.id}
                    className={
                      formValues?.collectionStorageId === storage?.id ? `active` : ""
                    }
                    data-id={storage?.id}
                    onClick={handleCollectionStorage}
                  >
                    {storage?.name}
                  </span>
                ))}
              </div>
              <Validators
                type="Required"
                value={formValues?.collectionStorageId}
                message="Required field"
                controlName="collectionStorageId"
                onErrors={collectErrors}
              />
            </div>
          </div>
          <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
            <div className="upload-banner flex flex-col gap-2">
              <label>Editions</label>
            </div>
            <div>
              <div className="flex flex-wrap sm:gap-[1rem] gap-[0.5rem] items-center new-checkbox">
                {collectionEditionsList?.map((edition) => (
                  <span
                    key={edition?.id}
                    className={
                      formValues?.collectionEditionsId === edition?.id ? `active` : ""
                    }
                    data-id={edition?.id}
                    onClick={handleCollectionEdition}
                  >
                    {edition?.name}
                  </span>
                ))}
              </div>
              <Validators
                type="Required"
                value={formValues?.collectionEditionsId}
                message="Required field"
                controlName="collectionEditionsId"
                onErrors={collectErrors}
              />
            </div>
          </div>            
          <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
            <div className="upload-banner flex flex-col gap-2">
              <label>Tags (Choose all that apply)</label>
            </div>
            <div>
              <div className={"flex mb-2"} style={{width:'20rem'}}>
                <InputBox                      
                  maxlength={100}
                  type="text"                                       
                  placeholder="Enter New Tag..."
                  onChange={inputOnChange}
                  width={10}  
                  controlName="newtag"    
                  value={formValues.newtag}                                                                            
                >
                </InputBox>                                           
                <Button
                  label="Save Tag"
                  onClick={handleNewCollectionTag}
                  className="transaction-cancel savetag"
                />
              </div>
              <div className="flex flex-wrap sm:gap-[1rem] gap-[0.5rem] items-center new-checkbox">
                {collectionTagList?.map((tag) => (
                  <span
                    key={tag?.id}
                    className={
                      formValues?.collectionTagIds?.includes(tag?.id) ? `active` : ""
                    }
                    data-id={tag?.id}
                    onClick={handleCollectionTag}
                  >
                    {tag?.name}
                  </span>
                ))}                    
              </div>                  
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <div className="section-title brd-btm w-full">Community*</div>
          </div>
          <div className="flex w-full gap-4">
            <div className="flex gap-4 w-full">
              <div className="flex w-full  flex-col gap-2">
                <div className="community-list-container flex gap-[1.42857rem] body flex-wrap">
                  {communityList?.map((community) => {
                    return (
                      <div
                        title={community?.name}
                        className={`item-container cursor-pointer ${
                          formValues.communityId === community?.id && "active"
                        }`}
                        data-id={community?.id}
                        key={community?.id}
                        onClick={handleCommunity}
                      >
                        <div className="image-container">
                          <img src={community?.displayPicture} alt="" />
                        </div>
                        <div className="title-container py-[0.72rem]">
                          <span>{community?.name}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <div className="section-title brd-btm w-full">Marketplace</div>
          </div>
          <div className="flex w-full gap-4">
            <div className="flex gap-4 w-full">
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <InputBox
                    label="Marketplace"
                    validateOnBlur
                    placeholder="https://www.marketplace.com/link"
                    type="text"
                    required={false}
                    onChange={inputOnChange}
                    controlName="marketplace"
                    value={formValues.marketplace}
                  />
                  <Validators
                    type="url"
                    value={formValues.marketplace}
                    message="URL not valid"
                    controlName="marketplace"
                    onErrors={collectErrors}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <div className="section-title brd-btm w-full">Socials</div>
          </div>
          <div className="flex flex-col w-full gap-4">
            <div className="flex w-full gap-4">
              <div className="flex w-full flex-col gap-2">
                <div>
                  <InputBox
                    placeholder="https://www.website.com"
                    label="Website"
                    validateOnBlur
                    type="text"
                    required={false}
                    onChange={inputOnChange}
                    controlName="website"
                    value={formValues.website}
                  />
                  <Validators
                    type="url"
                    value={formValues.website}
                    message="URL not valid"
                    controlName="website"
                    onErrors={collectErrors}
                  />
                </div>
              </div>
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <InputBox
                    placeholder="username"
                    label="Twitter"
                    validateOnBlur
                    type="text"
                    required={false}
                    onChange={inputOnChange}
                    controlName="twitter"
                    value={formValues.twitter}
                  />
                  {/* <Validators
                    type="url"
                    value={formValues.twitter}
                    message="URL not valid"
                    controlName="twitter"
                    onErrors={collectErrors}
                  /> */}
                </div>
              </div>
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <InputBox
                    label="Instagram"
                    placeholder="username"
                    validateOnBlur
                    type="text"
                    required={false}
                    onChange={inputOnChange}
                    controlName="instagram"
                    value={formValues.instagram}
                  />
                  {/* <Validators
                    type="url"
                    value={formValues.instagram}
                    message="URL not valid"
                    controlName="instagram"
                    onErrors={collectErrors}
                  /> */}
                </div>
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <InputBox
                    label="Discord"
                    placeholder="invitecode"
                    validateOnBlur
                    type="text"
                    required={false}
                    onChange={inputOnChange}
                    controlName="discord"
                    value={formValues.discord}
                  />
                  {/* <Validators
                    type="url"
                    value={formValues.discord}
                    message="URL not valid"
                    controlName="discord"
                    onErrors={collectErrors}
                  /> */}
                </div>
              </div>
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <InputBox
                    label="Youtube"
                    placeholder="channel"
                    validateOnBlur
                    type="text"
                    required={false}
                    onChange={inputOnChange}
                    controlName="youtube"
                    value={formValues.youtube}
                  />
                  {/* <Validators
                    type="url"
                    value={formValues.youtube}
                    message="URL not valid"
                    controlName="youtube"
                    onErrors={collectErrors}
                  /> */}
                </div>
              </div>
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <InputBox
                    label="Email"
                    placeholder="email@domain.com"
                    validateOnBlur
                    type="text"
                    required={false}
                    onChange={inputOnChange}
                    controlName="email"
                    value={formValues.email}
                  />
                  <Validators
                    type="Email"
                    value={formValues.email}
                    message="Email not valid"
                    controlName="email"
                    onErrors={collectErrors}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
            <TermsConditions/>
          <div
            className={`${isEditPage && "disabled"} flex flex-col w-full gap-4`}
          >
            <TermsTable
              termsList={termsList}
              handleTerms={handleTerms}
              values={formValues?.termsId}
            />
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex justify-center gap-4">
            <Button
              label="Cancel"
              onClick={gotToBack}
              className="transaction-cancel"
            />
            <Button
              className={`transaction-ok ${!isValid ? "disable" : ""}`}
              label={isVerfifyPage ? 'Verify' :`Update`}
              onClick={saveHandler}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCollection;
