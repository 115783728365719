import BreadCrumbs from "App/Components/UI/BreadCrumbs";
import Button from "App/Components/UI/Button";
import InputBox from "App/Components/UI/Forms/InputBox";
import { useStore } from "App/hooks-store/store";
import { useCallback, useEffect, useMemo, useState } from "react";
import WallDummy from "Assets/Images/WallDummy.svg";
import ImageReplace from "App/Components/UI/ImageReplace";
import { useNavigate, useParams } from "react-router-dom";
import { getInitial, markAsTouched, validateFormGroup } from "App/helpers/formValidation";
import Validators from "App/Components/UI/Forms/Validators";
import useHttp from "App/hooks/use-http";
import { extractFormFieldKeys, objectToFormdata } from "App/helpers/utilities";
import AutoComplete from "App/Components/UI/AutoComplete";
import ColorField from "App/Components/UI/Color";
import SelectBox from "App/Components/UI/SelectBox";

const breadCrumbs = [
    {
        link: '/dashboard/background-wall/wall-multi',
        label: 'Multi Wall'
    },
    {
        link: null,
        label: 'Create Multi Wall'
    }
]

const breadCrumbsEdit = [
    {
        link: '/dashboard/background-wall/wall-multi',
        label: 'Multi Wall'
    },
    {
        link: null,
        label: 'Edit Multi Wall'
    }
]

const CreateThumbnail = () => {
    const dispatch = useStore(false)[1];
    const { id } = useParams();
    const [formVaildState, setFormVaildState] = useState({});
    const { triggerAPI } = useHttp();
    const navigate = useNavigate()
    const [color, setColor] = useState()
    const [style, setStyle] = useState()

    const formGroup = useMemo(() => ({
        name: {
            value: undefined,
            isRequired: true
        },
        color: {
            value: undefined,
            isRequired: true
        },
        style: {
            value: undefined,
            isRequired: true
        },
        file: {
            value: undefined,
            isRequired: true
        },
        imageUrl: {
            value: undefined,
            isRequired: true
        }
    }), [])
    const [formValues, setFormvalues] = useState(getInitial(formGroup))
    const [colors,setColors] = useState([]);
    const wallCreationHandler = () => {
        navigate(`/dashboard/background-wall/wall-multi`);
    }

    const uploadHandler = (e) => {
        const reader = new FileReader()
        reader.addEventListener('load', (res) => {
            const imageUrl = res.target.result
            setFormvalues((prev) => {
                return {
                    ...prev,
                    file: e,
                    imageUrl: imageUrl
                }
            });
        })
        reader.readAsDataURL(e)
    }

    const apiResult = (res) => {
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
        wallCreationHandler()
    }

    const saveHandler = async () => {
        const { valid } = validateFormGroup({ errors: formVaildState, formGroup, values: formValues });
        if (valid) {
            if (id) {
                delete formValues.imageUrl;
                formValues.file = formValues.file ? formValues.file : ''
                const list = { ...formValues, id: id }
                const formData = objectToFormdata(list)
                dispatch('showSpinner');
                triggerAPI({
                    url: 'background-thumbnail/update', data: formData, method: 'post'
                }, apiResult);
            }
            else {
                delete formValues.imageUrl;
                const formData = objectToFormdata(formValues)
                dispatch('showSpinner');
                triggerAPI({
                    url: 'background-thumbnail/add', data: formData, method: 'post'
                }, apiResult);
            }
        }
        else {
            const newValues = markAsTouched(formValues);
            setFormvalues(newValues);
        }
    }

    const inputOnChange = (e) => {
        setFormvalues((prev) => {
            return {
                ...prev,
                ...e
            }
        })
    }

    const collectErrors = useCallback((error) => {
        setFormVaildState((prev) => {
            return {
                ...prev,
                ...error
            }
        })
    }, [])

    useEffect(() => {
        dispatch('setPageTitle', 'Background wall managment')
    }, [dispatch])

    const getWallResult = useCallback((res) => {
        dispatch('hideSpinner');
        const list = extractFormFieldKeys({ ...formGroup, id: '' }, res.data);
        setFormvalues(list)
    }, [dispatch, formGroup])

    const getWallDetails = useCallback(() => {
        dispatch('showSpinner');
        triggerAPI({
            url: `background-thumbnail/get/${id}`, method: 'get'
        }, getWallResult);
    }, [dispatch, getWallResult, id, triggerAPI])

    const getWallColorResult = useCallback((res) => {
        const list = res.data
        let result = list.map((a) => {
            return { value: a.color, name: a.color }
        })
        setColor(result)
    }, [])

    const getWallColor = useCallback(() => {
        triggerAPI({
            url: `background-thumbnail/color/get`, method: 'get'
        }, getWallColorResult);
    }, [getWallColorResult, triggerAPI])

    const getWallStyleResult = useCallback((res) => {
        const list = res.data
        let result = list.map((a) => {
            return { value: a.style, name: a.style }
        })
        setStyle(result)
    }, [])

    const getWallStyle = useCallback(() => {
        triggerAPI({
            url: `background-thumbnail/style/get`, method: 'get'
        }, getWallStyleResult);
    }, [getWallStyleResult, triggerAPI])

    useEffect(() => {
        if (id) {
            getWallDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getColors = () => {
        !id&&dispatch('showSpinner');
        triggerAPI({
            url: `frame-asset/color/get`, method: 'get'
        }, colorsApiResult);
    }
    const colorsApiResult = (res) => {
        !id&&dispatch('hideSpinner');
        const data = res.data
        let list
        list = data.map(color => { return { name: color.name, id: color.id } })
        list.reverse()
        setColors(list)
    }
    useEffect(() => {
         getWallStyle();
        // getWallColor()
        getColors()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <div className="flex justify-between">
                <div className="back">
                    <BreadCrumbs links={id ? breadCrumbsEdit : breadCrumbs} title={`${id ? "Edit Multi Wall" : "Create Multi Wall"}`} className='breadcrumb-thumbnail' />
                </div>
                <div className="flex gap-3">
                    <Button label="Cancel" className="transaction-cancel" onClick={wallCreationHandler} />
                    <Button label="Save" onClick={saveHandler} />
                </div>
            </div>

            <div className="flex pt-6 gap-4 wall">
                <div className="add-image">
                    <div className="file-validate pb-5">
                        <Validators type="Required" value={formValues?.imageUrl} message="Image is required" controlName="imageUrl" onErrors={collectErrors} />
                    </div>
                    <ImageReplace upload={`${id ? "Replace Wall" : "Upload Wall"}`} content="JPEG only, dimensions should be WxH" src={formValues?.imageUrl ? formValues.imageUrl : WallDummy} className="wall-upload" onChange={uploadHandler} />
                </div>
                <div className="column flex-1 wall-form">
                    <div>
                        <InputBox label="Name" maxLength={50} type="text" validateOnBlur controlName="name" value={formValues?.name} onChange={inputOnChange} />
                        <Validators type="Required" value={formValues?.name} message="Required field" controlName="name" onErrors={collectErrors} />
                    </div>
                    <div className="pt-6">
                    <ColorField label="Color" colors={colors} controlName="color" keyName="name" value={formValues?.color} onChange={inputOnChange} />
                        <Validators type="Required" value={formValues?.color} message="Required Field" controlName="color" onErrors={collectErrors} />
                    </div>
                    <div className="pt-6">
                    <SelectBox
                    validateOnBlur
                    list={style}
                    required={true}
                    placeHolder="Select"
                    keyName="name"
                    label="Style"
                    className="frame-form"
                    controlName="style"
                    onChange={inputOnChange}
                    currentId={formValues?.style}
                    tabIndex={0}
                  />                        <Validators type="Required" value={formValues?.style} message="Required Field" controlName="style" onErrors={collectErrors} />
                    </div>
                </div>
            </div>

        </div>
    )
}
export default CreateThumbnail;