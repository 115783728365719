import Button from "App/Components/UI/Button";
import SubscriptionPriceItem from "./SubscriptionPriceItem";
import SubscriptionDetailItem from "./SubscriptionDetailItem";
import SubscriptionActionContainer from "./SubscriptionActionContainer";
import SubscriptionListContainer from "./SubscriptionListContainer";

function SubscriptionList({ plan, handleAdd, handleDelete }) {
  return (
    <SubscriptionListContainer key={plan.id}>
      <SubscriptionPriceItem
        name={plan.name}
        planPrice={plan.planPrice}
        requiredMonetHolding={plan.requiredMonetHolding}
      />
      <SubscriptionDetailItem
        galleryType3D={plan.galleryType3D}
        availableGalleries={plan.availableGalleries}
        devices={plan.devices}
        anywallDisplayCount={plan.anywallDisplayCount}
        discoverProfile={plan.discoverableProfile}
      />
      <SubscriptionActionContainer>
        <Button
          className="transaction-cancel"
          row={plan}
          onClick={() => handleDelete(plan)}
          label="Delete"
        />
        <Button
          label="Edit"
          className="transaction-cancel"
          row={{ type: "edit", plan: {} }}
          onClick={() => handleAdd(plan)}
        />
      </SubscriptionActionContainer>
    </SubscriptionListContainer>
  );
}

export default SubscriptionList;
