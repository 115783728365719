/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import { useStore } from "App/hooks-store/store";
import { useCallback, useEffect, useState, useRef, useMemo } from "react";
import "./EditCommunity.scss";
import dummyImage from "Assets/Images/dummypic.svg";
import userAvatarHover from "Assets/Icons/avatar_hover.svg";
import useHttp from "App/hooks/use-http";
import { useNavigate, useParams } from "react-router-dom";
import ArrowLeft from "Assets/Images/pagination-left-active.svg";
import { objectToFormdata } from "App/helpers/utilities";
import {
  getInitial,
  markAsTouched,
  validateFormGroup,
} from "App/helpers/formValidation";
import Button from "App/Components/UI/Button";
import Validators from "App/Components/UI/Forms/Validators";
import TextArea from "App/Components/UI/TextArea";
import InputBox from "App/Components/UI/Forms/InputBox";
import {
  handleDragEnter,
  handleDragLeave,
  handleDragOver,
  handleDrop,
} from "App/helpers/file";
const EditCommunity = () => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const bannerWrapRef = useRef(null);
  const profileWrapRef = useRef(null);
  const [formValues, setFormvalues] = useState({});
  const formGroup = useMemo(() => {
    return {
      name: {
        value: undefined,
        isRequired: false,
      },
      bannerPictureFile: {
        value: undefined,
        isRequired: false,
      },
      displayPictureFile: {
        value: undefined,
        isRequired: false,
      },
      description: {
        value: undefined,
        isRequired: false,
      },
      about: {
        value: undefined,
        isRequired: false,
      },
      instagram: {
        value: undefined,
        isRequired: false,
      },
      twitter: {
        value: undefined,
        isRequired: false,
      },
      website: {
        value: undefined,
        isRequired: false,
      },
      discord: {
        value: undefined,
        isRequired: false,
      },
      youtube: {
        value: undefined,
        isRequired: false,
      },
      blockChain: {
        value: undefined,
        isRequired: false,
      },
      tokenName: {
        value: undefined,
        isRequired: false,
      },
      tokenId: {
        value: undefined,
        isRequired: false,
      },
    };
  }, [formValues]);
  useEffect(() => {
    setFormvalues(getInitial(formGroup));
  }, []);
  const { id } = useParams();
  const { triggerAPI } = useHttp();
  const dispatch = useStore(false)[1];
  const [formVaildState, setFormVaildState] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [touched, setTouched] = useState(false);
  const [displayPicture, setDisplayPicture] = useState(null);
  const [bannerPicture, setBannerPicture] = useState(null);

  const bannerRef = useRef(null);
  const displayImgRef = useRef(null);
  let navigate = useNavigate();
  const gotToBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (id) {
      getDetailedCommunity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const getDetailedCommunityResult = useCallback(
    (res) => {
      setTimeout(() => {
        dispatch("hideSpinner");
      }, 300);
      const { data } = res;
      const fungibleTokens = data?.fungibleTokens?.length
        ? data?.fungibleTokens[0]
        : {};
      setFormvalues({
        name: data?.name ?? '',
        description: data?.description ?? '',
        about: data?.about ?? '',
        website: data?.website ?? '',
        twitter: data?.twitter,
        instagram: data?.instagram ?? '',
        discord: data?.discord ?? '',
        tokenName: fungibleTokens?.tokenName ?? '',
        tokenId: fungibleTokens?.policyId ?? '',
        blockChain: fungibleTokens?.blockchainTypeId ?? '',
        bannerPictureFile: data?.bannerPicture ?? '',
        displayPictureFile: data?.displayPicture ?? '',
      });
      setBannerPicture(data?.bannerPicture);
      setDisplayPicture(data?.displayPicture);
    },
    [dispatch]
  );
  const getDetailedCommunity = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/get/${id}`,
        method: "get",
      },
      getDetailedCommunityResult
    );
  }, [dispatch, getDetailedCommunityResult, triggerAPI, id]);

  useEffect(() => {
    if (touched) {
      const newValues = markAsTouched(formValues);
      setFormvalues(newValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touched]);

  useEffect(() => {
    const { valid } = validateFormGroup({
      errors: formVaildState,
      formGroup,
      values: formValues,
    });
    setIsValid(valid);
  }, [formValues, formVaildState, formGroup]);

  const collectErrors = useCallback((error) => {
    setFormVaildState((prev) => {
      return {
        ...prev,
        ...error,
      };
    });
  }, []);
  const inputOnChange = (e) => {
    setFormvalues((prev) => {
      return {
        ...prev,
        ...e,
      };
    });
  };
  const getResult = useCallback(
    (res) => {
      dispatch("hideSpinner");
      gotToBack();
      dispatch("showToast", {
        toast: { toastMode: "success", message: res?.message },
      });
    },
    [dispatch, navigate]
  );

  const saveHandler = useCallback(() => {
    if (isValid) {
      dispatch("showSpinner");
      const list = { ...formValues };
      list.fungibleTokens = [];
      let tempFungibleTokens = {};
      if (list?.tokenName) tempFungibleTokens.tokenName = list?.tokenName;
      if (list?.tokenId) tempFungibleTokens.policyId = list?.tokenId;
      if (list?.blockChain)
        tempFungibleTokens.blockchainTypeId = list?.blockChain;
      if (Object.keys(tempFungibleTokens).length)
        list.fungibleTokens.push(tempFungibleTokens);
      if (
        !list?.bannerPictureFile ||
        typeof list?.bannerPictureFile === "string"
      )
        delete list.bannerPictureFile;
      if (
        !list?.displayPictureFile ||
        typeof list?.displayPictureFile === "string"
      )
        delete list.displayPictureFile;
      if (!list.fungibleTokens?.length) delete list.fungibleTokens;
      delete list.tokenName;
      delete list.tokenId;
      delete list.blockChain;
      const formData = objectToFormdata(list);
      triggerAPI(
        {
          url: `community/${id}`,
          data: formData,
          method: "put",
        },
        getResult,
        (err) => {
          dispatch("hideSpinner");
          dispatch("showToast", {
            toast: {
              toastMode: "error",
              message:
                err?.response?.data?.error?.message ||
                "Editing community failed",
            },
          });
        }
      );
    } else {
      setTouched(true);
    }
  }, [dispatch, formValues, getResult, isValid, triggerAPI, id]);

  const imageHandle = (imgFile) => {
    if (imgFile) {
      const file = imgFile?.bannerPicture || imgFile?.displayPicture;
      const fileSize = file.size / 1024 / 1024;
      const extension = file?.name.split(".").pop();
      const fileExtension = extension.toLowerCase();
      if (
        fileExtension === "png" ||
        fileExtension === "jpeg" ||
        fileExtension === "jpg" ||
        fileExtension === "gif" ||
        fileExtension === "webp" ||
        fileExtension === "svg"
      ) {
        if (fileSize <= 2) {
          const imageUrl = URL.createObjectURL(file);
          if (imgFile?.bannerPicture) {
            inputOnChange({ bannerPictureFile: file });
            setBannerPicture(imageUrl);
          }
          if (imgFile?.displayPicture) {
            inputOnChange({ displayPictureFile: file });
            setDisplayPicture(imageUrl);
          }
        } else {
          dispatch("showToast", {
            toast: {
              toastMode: "error",
              message: "The maximum size of an image should be 2 MB",
            },
          });
        }
      } else {
        dispatch("showToast", {
          toast: {
            toastMode: "error",
            message: "Image format should be png, jpeg, jpg, gif, svg or webp",
          },
        });
      }
    }
  };
  const imgUpload = (ref) => {
    ref.current.click();
  };

  return (
    <div className="community-form community-edit text-left flex flex-col gap-4">
      <div className="flex gap-2 items-center">
        <img
          className="breadcrumbs-img"
          src={ArrowLeft}
          alt="img"
          onClick={gotToBack}
        />
        <h2>{formValues.name}</h2>
      </div>
      <div className="flex flex-col gap-4">
        <div>
          <div className="section-title brd-btm w-full">Community</div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="upload-banner flex flex-col gap-2">
            <label>Community Banner*</label>
          </div>
          <div
            className="upload-banner-image"
            ref={bannerWrapRef}
            id="banner"
            onDrop={(e) =>
              handleDrop(
                e,
                bannerWrapRef,
                imageHandle,
                "bannerPicture",
                "banner"
              )
            }
            onDragOver={handleDragOver}
            onDragEnter={(e) => handleDragEnter(e, bannerWrapRef, "banner")}
            onDragLeave={(e) => handleDragLeave(e, bannerWrapRef, "banner")}
          >
            <div
              className={"relative h-full w-full"}
              onClick={() => imgUpload(bannerRef)}
            >
              <label className="cursor-pointer" htmlFor="upload-button">
                <img
                  alt="No Img"
                  src={userAvatarHover}
                  className={`upload-icon`}
                ></img>
                {!bannerPicture && (
                  <div className="dummy-wrap">
                    <img
                      className={`image-uploaded`}
                      alt="NoImg"
                      src={dummyImage}
                    ></img>
                    <p>
                      Maximum file size 2MB <br />
                      Recommended size <br />
                      1200px x 240px <br />
                      6:1
                    </p>
                  </div>
                )}
                {bannerPicture && (
                  <img
                    alt="No Img"
                    className={`image-uploaded actual-img`}
                    src={bannerPicture}
                  ></img>
                )}
              </label>
              <InputBox
                type="file"
                isFile={true}
                fieldRef={bannerRef}
                id="upload-button"
                controlName={"bannerPicture"}
                className={"hidden"}
                accept="image/*"
                onChange={imageHandle}
              />
            </div>
            
          </div>
        </div>
        <div className="flex flex-col gap-4 ">
          <div className="flex gap-4 sm:flex-row flex-col">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-2">
                  <label className="mt-[7px]">Community Image*</label>
                </div>
                <div
                  className="upload-banner-image profile-img my-0 mx-auto"
                  ref={profileWrapRef}
                  onDrop={(e) =>
                    handleDrop(
                      e,
                      profileWrapRef,
                      imageHandle,
                      "displayPicture",
                      "pro-pic"
                    )
                  }
                  id="pro-pic"
                  onDragOver={handleDragOver}
                  onDragEnter={(e) =>
                    handleDragEnter(e, profileWrapRef, "pro-pic")
                  }
                  onDragLeave={(e) =>
                    handleDragLeave(e, profileWrapRef, "pro-pic")
                  }
                >
                  <div
                    className={"relative h-full w-full"}
                    onClick={() => imgUpload(displayImgRef)}
                  >
                    <label
                      className="cursor-pointer"
                      htmlFor="upload-button-displayPicture"
                    >
                      <img
                        alt="No Img"
                        src={userAvatarHover}
                        className={`upload-icon`}
                      ></img>
                      {!displayPicture && (
                        <div className="dummy-wrap">
                          <img
                            className={`image-uploaded`}
                            alt="NoImg"
                            src={dummyImage}
                          ></img>
                          <p>
                            Maximum file <br />
                            size 2MB <br />
                            Minimum size <br />
                            92px x 92px <br />
                            1:1
                          </p>
                        </div>
                      )}
                      {displayPicture && (
                        <img
                          alt="No Img"
                          className={`image-uploaded`}
                          src={displayPicture}
                        ></img>
                      )}
                    </label>
                    <InputBox
                      type="file"
                      isFile={true}
                      fieldRef={displayImgRef}
                      id="upload-button-displayPicture"
                      controlName={"displayPicture"}
                      className={"hidden"}
                      accept="image/*"
                      onChange={imageHandle}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4 w-full">
              <div className="flex flex-col gap-2">
                <div>
                  <InputBox
                    maxlength={35}
                    type="text"
                    label="Community"
                    required={true}
                    validateOnBlur
                    onChange={inputOnChange}
                    controlName="name"
                    value={formValues.name}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div>
                  <TextArea
                    placeholder={
                      "Describe the token including what it is, are potential benefits, roadmap (1000 words max)"
                    }
                    label="Description"
                    showTextCount={true}
                    validateOnBlur
                    required={true}
                    type="text"
                    rows={10}
                    onChange={inputOnChange}
                    maxLength={300}
                    controlName="description"
                    value={formValues.description}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div>
            <TextArea
              placeholder={
                "Describe the token including what it is, are potential benefits, roadmap (1000 words max)"
              }
              rows={10}
              label="About Us"
              required={true}
              showTextCount={true}
              validateOnBlur
              type="text"
              onChange={inputOnChange}
              maxLength={300}
              controlName="about"
              value={formValues.about}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div>
          <div className="section-title brd-btm w-full">Socials</div>
        </div>
        <div className="flex flex-col w-full gap-4">
          <div className="flex w-full gap-4">
            <div className="flex w-full flex-col gap-2">
              <div>
                <InputBox
                  label="Website"
                  validateOnBlur
                  required={false}
                  placeholder="https://www.website.com"
                  type="text"
                  onChange={inputOnChange}
                  controlName="website"
                  value={formValues.website}
                />
                <Validators
                  type="url"
                  value={formValues.website}
                  message="URL not valid"
                  controlName="website"
                  onErrors={collectErrors}
                />
              </div>
            </div>
            <div className="flex w-full  flex-col gap-2">
              <div>
                <InputBox
                  label="Twitter"
                  validateOnBlur
                  required={false}
                  placeholder="username"
                  type="text"
                  onChange={inputOnChange}
                  controlName="twitter"
                  value={formValues.twitter}
                />
                {/* <Validators
                  type="url"
                  value={formValues.twitter}
                  message="URL not valid"
                  controlName="twitter"
                  onErrors={collectErrors}
                /> */}
              </div>
            </div>
            <div className="flex w-full  flex-col gap-2">
              <div>
                <InputBox
                  label="Instagram"
                  validateOnBlur
                  required={false}
                  placeholder="username"
                  type="text"
                  onChange={inputOnChange}
                  controlName="instagram"
                  value={formValues.instagram}
                />
                {/* <Validators
                  type="url"
                  value={formValues.instagram}
                  message="URL not valid"
                  controlName="instagram"
                  onErrors={collectErrors}
                /> */}
              </div>
            </div>
          </div>
          <div className="flex w-full flex-col sm:flex-row gap-4">
          <div className="flex gap-4 w-[32.5%]">
            <div className="flex w-full  flex-col gap-2">
              <div>
                <InputBox
                  label="Discord"
                  validateOnBlur
                  placeholder="invitecode"
                  type="text"
                  required={false}
                  onChange={inputOnChange}
                  controlName="discord"
                  value={formValues.discord}
                />
                {/* <Validators
                  type="url"
                  value={formValues.discord}
                  message="URL not valid"
                  controlName="discord"
                  onErrors={collectErrors}
                /> */}
              </div>
            </div>
            
          </div>
          <div className="flex gap-4 w-[32.5%]">
            <div className="flex w-full  flex-col gap-2">
              <div>
                <InputBox
                  label="Youtube"
                  validateOnBlur
                  placeholder="channel"
                  type="text"
                  required={false}
                  onChange={inputOnChange}
                  controlName="youtube"
                  value={formValues.youtube}
                />
                {/* <Validators
                  type="url"
                  value={formValues.youtube}
                  message="URL not valid"
                  controlName="youtube"
                  onErrors={collectErrors}
                /> */}
              </div>
            </div>
            
          </div>
          </div>
        </div>
      </div>      
      <div className="flex justify-center gap-4">
        <Button
          label="Cancel"
          onClick={gotToBack}
          className="transaction-cancel"
        />
        <Button
          className={`transaction-ok ${!isValid ? "disable" : ""}`}
          label={`Update`}
          onClick={saveHandler}
        />
      </div>
    </div>
  );
};

export default EditCommunity;
