
export const getTwoDigitCount = (num = 0) => {
    return num.toString().padStart(2, 0)
}

export const getHexToStr = (hexStr) => {
    let hex = hexStr?.toString();
    let strResult = '';
    for (let n = 0; n < hex?.length; n += 2) {
        strResult += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    return strResult;
}

export const gcd = (width, height) => {
    return (height === 0) ? width : gcd(height, width % height);
}

export const aspectRatio = (width, height) => {
    const changedWidth = !!Number(width) ? Number(width) : 1
    const changedHeight = !!Number(height) ? Number(height) : 1
    const gcdValue = gcd(changedWidth, changedHeight);
    const arw = changedWidth / gcdValue;
    const arh = changedHeight / gcdValue;
    return `${Number((arw / arw))} : ${Number((arh / arw).toFixed(2))}`;
}

export const safeJsonParse = (json) => {
    let result = {}
    try {
        result = JSON.parse(json)

    } catch (error) {
        result = {}

    }
    return result;
}

export const objectToFormdata = (payload = {}) => {
    const formData = new FormData();
    for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
            const element = payload[key];
            if (element && typeof element === 'object' && !(element instanceof Blob)) {
                formData.append(key, JSON.stringify(element))
            }
            else {
                formData.append(key, element)
            }

        }
    }
    return formData
}

export const extractFormFieldKeys = (formValues, payload) => {
    let result = {};
    for (const key in formValues) {
        if (Object.hasOwnProperty.call(formValues, key) && Object.hasOwnProperty.call(payload, key)) {
            const element = payload[key];
            result[key] = element;
        }
    }
    return result
}
export const convertPriceToAda = (priceValue) => {
    const price = (priceValue / 1000000);
    return price;
}

export const convertJsonToArray = (data) => {
    try {
        const convertData = JSON.parse(data);
        return typeof convertData === 'object' ? convertData : [];
    } catch (error) {
        return [];
    }
}

export const getWidthHeight = (file) =>{
    let width;
    let height;
    const reader = new FileReader();
reader.onload = function (e) {
  const img = new Image();
  img.onload = function () {
   width = img.width;
   height = img.height;

  };
  img.src = e.target.result;
};
reader.readAsDataURL(file);

return {width,height}
}

export const objectToNull = (payload = {}) => {
    let obj={}
    for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
            const element = payload[key];
        obj[key] = element?element:null

        }
    }
    return obj
}

export const copyClipHandler = (value,callback) => {
    var textArea = document.createElement("textarea");
    textArea.value = value;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.width = ".09px";
    textArea.style.opacity = "0";

    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    callback(true)
}