/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";

import useHttp from "App/hooks/use-http";
import { useStore } from "App/hooks-store/store";

import CollectionListing from "../../Components/Table";
import { LIST_LIMIT } from "App/constants";

const paginationConfig = {
  perPage: LIST_LIMIT,
};
const VerifyCollectionsList = () => {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const dispatch = useStore(false)[1];
  const { triggerAPI } = useHttp();
  const [filteredData, setFilteredData] = useState("");
  const [initial, setInitial] = useState(true);
  const [selectedData, setSelectedData] = useState({});
  const [showActivatePop, setShowActivatePop] = useState({ mode: false });

  const [sort, setSort] = useState({ direction: "ASC", sortKey: "createdAt" });

  const [list, setList] = useState([]);
  const [search, setSearch] = useState('');
  const searchHandler = (e) => {
    setSearch(e);
  }

  const getNewCollectionListResult = useCallback(
    (res) => {
      const { list, pageMeta } = res.data;

      // const getOneApiResult = (id) => {
      //   return new Promise((resolve) => {
      //     triggerAPI(
      //       {
      //         url: `community/collection/get-one`,
      //         data: {
      //           id: Number(id),
      //         },
      //         method: "post",
      //       },
      //       resolve
      //     );
      //   });
      // };
      // const promiseList = list?.map((val) => getOneApiResult(val?.id));
      //   Promise.all(promiseList).then((res) => {
      //    let collection= list?.map((ele)=>{
      //     let resData=res?.find(val=>val?.data?.id===ele?.id)
      //     return {...ele,owners:resData?.data?.ownersCount,items:resData?.data?.items}
      //    })
      //     setList(collection);
      //     setTotalPage(pageMeta.totalItems);
      //     setTimeout(()=>{
      //       dispatch("hideSpinner");
      //     },300)
      //   });

      let collection = list?.map((ele) => {
        return { ...ele, owners: ele?.ownersCount, items: ele?.assetCount }
      });
      setList(collection);
      setTotalPage(pageMeta.totalItems);
      setTimeout(() => {
        dispatch("hideSpinner");
      }, 300);
    },
    [dispatch]
  );

  const getNewCollectionList = useCallback(() => {
    dispatch("showSpinner");
    const filter = {
      sortBy: sort.sortKey ? sort.sortKey : "ASC",
      orderBy: sort.sortKey ? sort.direction : "createdAt",
      ...filteredData,
    };

    triggerAPI(
      {
        url: `community/collection/temp/get`,
        data: {
          page: page,
          items: paginationConfig.perPage,
          search: `${search}`,
          filter: { status: ["ownership_requested"], ...filter },
        },
        method: "post",
      },
      getNewCollectionListResult
    );
  }, [
    dispatch,
    filteredData,
    search,
    getNewCollectionListResult,
    page,
    sort.direction,
    sort.sortKey,
    triggerAPI,
  ]);

  const apiResult = (res) => {
    const { message } = res;
    dispatch("hideSpinner");
    dispatch("showToast", { toast: { toastMode: "success", message } });
    if (showActivatePop?.mode) setShowActivatePop({ mode: false });
    getNewCollectionList();
  };

  const activateHandler = (action) => {
    const status = `${action === "activate" ? "active" : action === "reject" ? "rejected" : "blocked"}`;
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/collection/temp/update-status`,
        data: {
          id: selectedData?.id,
          status,
        },
        method: "patch",
      },
      apiResult,
      (err) => {
        dispatch("hideSpinner");
        dispatch("showToast", {
          toast: {
            toastMode: "error",
            message:
              err?.response?.data?.error?.message ||
              `${
                action === "activate" ? "Blocking" : "Activating"
              } community failed`,
          },
        });
      }
    );
  };

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      getNewCollectionList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, filteredData, search]);

  useEffect(() => {
    if (!initial) {
      getNewCollectionList();
    }
    setInitial(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    dispatch("setPageTitle", "Collection Management");
  }, [dispatch]);

  return (
    <CollectionListing
      sort={sort}
      selectedData={selectedData}
      showActivatePop={showActivatePop}
      list={list}
      page={page}
      totalPage={totalPage}
      paginationConfig={paginationConfig}
      setPage={setPage}
      setSelectedData={setSelectedData}
      setFilteredData={setFilteredData}
      setShowActivatePop={setShowActivatePop}
      filteredData={filteredData}
      setSort={setSort}
      activateHandler={activateHandler}
      onSearchChange = {searchHandler}
      isTemporary={true}
    />
  );
};

export default VerifyCollectionsList;
