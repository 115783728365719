function SubscriptionDetailItem({
  galleryType3D,
  availableGalleries,
  devices,
  anywallDisplayCount,
  discoverProfile,
}) {
  return (
    <div className="col-span-1 p-4 font-thin leading-loose">
      <div>
        <p>{"Collection Management"}</p>
        <p>
          <span className="font-bold">{galleryType3D}</span> 3D{" "}
          {galleryType3D.toLowerCase() == "all" ? "Galleries" : "Gallery"}
        </p>
        <p>
          <span className="font-bold">{availableGalleries}</span>{" "}
          {Number(availableGalleries) > 1 ? "Galleries" : "Gallery"}
        </p>
        <p>
          Use on <span className="font-bold">{devices}</span>{" "}
          {devices > 1 ? "devices" : "device"} at once
        </p>
        {anywallDisplayCount > 0 ? (
          <p>
            Display on <span className="font-bold">{anywallDisplayCount}</span>{" "}
            Anywall display
          </p>
        ) : (
          <p>
            <span className="font-bold">{"No"}</span> Anywall display
          </p>
        )}
        <p>
          <span className="font-bold">
            {discoverProfile ? "Discoverable" : "Undiscoverable"}
          </span>{" "}
          Profile
        </p>
      </div>
    </div>
  );
}

export default SubscriptionDetailItem;
