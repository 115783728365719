import { Outlet } from "react-router-dom";
import LineItem from "../../Components/LineItem";
import './RegistrationRewards.scss';
import Popup from "App/Components/UI/Popup";
import { useStore } from "App/hooks-store/store";
import { useCallback, useEffect, useState } from "react";
import useHttp from "App/hooks/use-http";
import RewardForm from "../../Components/RewardForm";

const RegistrationReward = () => {
    const dispatch = useStore(false)[1];
    useEffect(() => {
        dispatch('setPageTitle', 'Rewards')
    }, [dispatch])

    const { triggerAPI } = useHttp();
    const [list, setList] = useState([]);

    const [selectedData, setSelectedData] = useState({});
    const [showMonetRewarPopup, setShowMonetRewarPopup] = useState({
        mode: false,
    });

    const getRewardResponse = useCallback(
      (res) => {
        dispatch("hideSpinner");
        const { data } = res;
        setList(data);
      },
      [dispatch]
    );
  
    const getMonetReward = useCallback(() => {
      triggerAPI(
        {
          url: `admin/rewards/monet/get`,
          method: "get",
        },
        getRewardResponse
      );
    }, []);
  
    useEffect(() => {
      dispatch("showSpinner");
  
      getMonetReward();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

 

    const onCloseReward = (data) => {
        setShowMonetRewarPopup({ mode: false });
        if (data) {
            getMonetReward();
        }
    };

    
  const actionHandler = (data) => {
    setSelectedData(data);
    setShowMonetRewarPopup({mode: true})
  };

    return (
        <div className="rewards-main">
            <div className="py-4 regWrp">
              <div className="title">
                   Registration
              </div>
              <div>
                {list.length ? (
                    list.map((plan) => <LineItem key={plan.id} data={plan} actionHandler={actionHandler}/>)
                    ) : (
                    <p className="mt-6 text-center">
                        Oops! There are no available rewards
                    </p>
                )}
              </div>
            </div>
            <Popup
                show={showMonetRewarPopup}
                style={{ maxWidth: "40rem" }}
                title="Registration Rewards"
                cssClass="user-create-pop"
                onClose={onCloseReward}
            >
                <RewardForm
                    list={null}
                    triggerClose={onCloseReward}
                    row={selectedData}
                    />
                    <div>Try</div>
            </Popup>
        </div>
    )
}
export default RegistrationReward;